@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sorts+Mill+Goudy:ital@0;1&display=swap');

/*=========================================================*/
:root {
--color-primary: rgb(226, 216, 215);
--color-secondary: #CE8992;
--color-white: #ffffff;
--color-dark: #000000;
--color-light: #f2efee;
--color-light01: rgb(242, 239, 238);
--color-gray-100: #f4f5f8;
--color-gray-200: #e3e7ed;
--color-gray-300: #d4d4d7;
--color-gray-400: #e6e6e6;
--color-gray-500: #606161;
--color-gray-600: #3f3f40;
--color-gray-700: #404041;
--color-gray-800: #3b4863;
--color-gray-900: #1c273c;
--font-primary: "League Spartan", sans-serif;
--font-secondary: "Sorts Mill Goudy", serif;
}
/*==========================================================*/
body {
font-family: var(--font-primary) !important;
font-size: 1rem !important;
line-height: 20px;
}
a {
text-decoration: none !important;
color: var(--color-gray-900);
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
font-weight: 400 !important;
font-family: var(--font-secondary) !important;
}
.h1,
h1 {
font-size: 3.5rem;
}
figure {
margin: 0 !important;
}
textarea:focus,
input:focus {
outline: none;
}
.container-fluid {
padding-left: 50px;
padding-right: 50px;
}

@media only screen and (max-width: 968px) {
.container-fluid {
padding-left: 15px;
padding-right: 15px;
}
}
textarea:focus,
input:focus,
select:focus,
.form-select:focus {
outline: none;
box-shadow: none !important;
}
/*=========================================================*/
.bg-primary {
background: var(--color-primary) !important;
}
.bg-light {
background: var(--color-light) !important;
}
.bg-light01 {
background: var(--color-light01) !important;
}
.tx-12 {
font-size: 12px;
}

.tx-13 {
font-size: 13px;
}

.tx-14 {
font-size: 14px;
}

.tx-15 {
font-size: 15px;
}

.tx-16 {
font-size: 16px;
}

.tx-18 {
font-size: 18px;
}

.tx-20 {
font-size: 20px;
}

.fw600 {
font-weight: 600 !important;
}

.text-underlined {
text-decoration: underline;
}
.tx-primary {
color: var(--color-secondary);
}
.tx-gray {
color: var(--color-gray-500);
}

.tx-center {
text-align: center;
}

.tx-left {
text-align: left;
}

.tx-right {
text-align: right;
}
.tx-uppercase {
text-transform: uppercase;
}
.divider {
display: inline-block;
height: 15px;
width: 2px;
background-color: var(--color-white);
}

.dividerDark {
display: inline-block;
height: 1px;
width: 100%;
background-color: var(--color-gray-500);
}

.dividerLight {
display: inline-block;
height: 1px;
width: 100%;
background-color: var(--color-gray-200);
}

.header:hover .divider {
background-color: var(--color-dark);
}

.section-gap-sm {
padding: 30px 0px;
}

.section-gap-md {
padding: 60px 0px;
}

.section-gap-lg {
padding: 80px 0px;
}

.section-gap-large {
padding: 80px 0px;
}

.section-gap-medium {
padding: 60px 0px;
}

.section-gap-small {
padding: 30px 0px;
}

.sectiontop-gap-large {
padding-top: 80px;
}

.sectiontop-gap-medium {
padding-top: 60px;
}

.sectiontop-gap-small {
padding-top: 30px;
}

.sectionbottom-gap-large {
padding-bottom: 80px;
}

.sectionbottom-gap-medium {
padding-bottom: 60px;
}

.sectionbottom-gap-small {
padding-bottom: 30px;
}

.btn {
border-radius: 0px !important;
padding: 14px 15px 10px;
}

.btn-sm {
padding: 7px 10px 6px;
font-size: 14px;
}

.btn-lg {
padding: 14px 15px 11px;
font-size: 18px;
}

.btn-full {
width: 100%;
}

.btn-primary {
background: var(--color-dark) !important;
border-color: var(--color-dark) !important;
}

.btn-primary-outline {
background: transparent !important;
border-color: var(--color-dark) !important;
}

.btn-transparent {
background: transparent;
}

.btn-primary-line {
background: transparent;
border-bottom: 1px solid var(--color-dark) !important;
border-radius: 0 !important;
padding: 0 !important;
}

.btn-ghost-primary {
background: transparent;
border: 1px solid transparent;
}

.btn-underline-primary {
background: transparent;
border: 1px solid transparent;
border-bottom: 1px solid var(--color-primary);
border-radius: 0px;
}

.btn-ulg {
font-size: 18px;
}

.section-title {
display: inline-block;
width: 100%;
position: relative;
}

.section-title h2 {
font-size: 2.5rem;
}
@media only screen and (max-width: 968px) {
.section-title h2 {
font-size: 1.5rem;
}   
}
.w-lg {
min-width: 250px;
}

.wd-18 {
width: 18px !important;
}

.wd-24 {
width: 24px !important;
}

.wd-28 {
width: 28px !important;
}

.wd-50 {
width: 50px !important;
}

.wd-100 {
width: 100px !important;
}

.wd-120 {
width: 120px !important;
}

.wd-150 {
width: 150px !important;
}
.wd-200 {
width: 200px !important;
}
.wd-250 {
width: 250px !important;
}

.img-fluid {
width: 100%;
}

.border-right {
border-right: 1px solid var(--color-gray-200);
}
@media only screen and (max-width:968px) {
.section-gap-medium {
padding: 30px 0px;
}
}
/*=========================================================*/
.header {
position: absolute;
top: 0;
left: 0;
width: 100%;
color: var(--color-white);
transition: transform .4s;
background: linear-gradient(to bottom, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%);
/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
z-index: 99;
}

.header.innerHeader {
position: relative;
background: var(--color-primary) !important;
color: var(--color-dark);
}

@media only screen and (max-width: 968px) {
.header {
padding: 10px 0px;
}
}

.header:hover {
background: var(--color-primary);
transition: all .3s;
color: var(--color-dark);
}

.header .logo {
display: block;
transition: all .6s ease-in-out
}

.header .logo img {
width: 250px;
height: auto;
}

.innerHeader .logo {
display: none;
}

.header:hover .logo {
display: none;
}

.header .hoverlogo {
display: none;
}

.innerHeader .hoverlogo {
display: block;
}

.header .hoverlogo img {
width: 250px;
height: auto;
}

.header:hover .hoverlogo {
display: block;
}

.cartHeaderLogo img {
width: 250px;
height: auto;
}

.header .container,
.header .container-fluid {
display: flex;
align-items: center;
}

.header .headerLeft,
.headerRight,
.headerMiddle {
flex: 1;
}

.header .headerLeft,
.headerRight {
display: flex;
}

.header-title {
display: flex;
align-items: center;
gap: 10px;
}

.header-title h1 {
font-size: 18px;
}

.header-title i {
font-size: 24px;
}

.header-top {
width: 100%;
position: relative;
padding: 5px 0px;
}

.header-middle {
width: 100%;
position: relative;
padding: 10px 0px;
}

.fixed-head {
left: 0;
position: fixed !important;
top: -40px;
transform: translateY(40px);
z-index: 999;
background: var(--color-white);
transition: .5s cubic-bezier(.25, .8, .25, 1) 0.05s;
width: 100%;
}

.htrlist {
margin: 0;
padding: 0;
list-style: none;
display: flex;
align-items: center;
gap: 20px;
}

.htrlist li {
display: inline-block;
position: relative;
}

.htrlist li a {
display: flex;
align-items: center;
color: var(--color-white);
}

.innerHeader .htrlist li a {
color: var(--color-dark);
}

.htrlist li.wishlist {
margin-top: 5px;
margin-left: 20px;
position: relative;
}

.htrlist li.wishlist .count {
width: 14px;
height: 14px;
border-radius: 50%;
background: var(--color-white);
position: absolute;
bottom: -5px;
right: -5px;
display: flex;
align-items: center;
justify-content: center;
color: var(--color-gray-900);
font-size: 10px;
}

.header:hover .htrlist li a {
color: var(--color-dark);
}

.navbar {
padding: 0 !important;
}
.navbar-expand-lg {
flex-wrap: nowrap;
justify-content: flex-start;
}
.navbar-expand-lg .navbar-nav {
flex-direction: row;
}
.nav-link {
padding: 1rem 1.2rem !important;
position: relative;
color: var(--color-white);
font-size: 1.1rem;
}
.fixed-head .nav-link{
padding: 1.8rem 1.2rem !important; 
}
.innerHeader .nav-link {
color: var(--color-dark);
}
.nav-link::after {
content: "";
position: absolute;
width: 0;
height: 2px;
background: var(--color-secondary);
transition: 0.5s;
opacity: 0;
bottom: 0;
left: 0;
}
.nav-link:hover::after {
opacity: 1;
width: 100%;
transition: 0.5s;
}
.header:hover .nav-link {
color: var(--color-dark);
}
.navLogo {
display: none;
}
.navLogo .nav-link{
padding:0px 30px 0px 0px !important;
}
.navLogo .nav-link::after {
display: none;
}
.navLogo img {
width: 125px;
height: auto;
}
.fixed-head .nav-link {
color: var(--color-dark);
}
.fixed-head .navLogo {
display: block;
}
.cartToggle {
position: relative;
}
.cartToggle .count {
align-items: center;
background: var(--color-secondary);
border-radius: 50%;
color: #fff;
display: flex;
font-size: 11px;
height: 18px;
justify-content: center;
line-height: 18px;
position: absolute;
right: 15px;
top: 10px;
width: 18px;
}
@media only screen and (max-width:968px) {
.cartToggle .count {
right: -10px;
top: -10px;
}
}
.navRightlist {
margin: 0;
padding: 0;
list-style: none;
display: flex;
align-items: center;
gap: 10px;
}

.navRightlist li a {
display: inline-block;
color: var(--color-white);
}

.header:hover .navRightlist li a {
color: var(--color-dark);
}

.leftHeaderlist {
align-items: center;
display: flex;
gap: 20px;
list-style: none;
margin: 0;
padding: 0;
}

.leftHeaderlist li {
display: inline-block;
position: relative;
}

.leftHeaderlist li a {
display: flex;
align-items: center;
color: var(--color-white);
}

.innerHeader .leftHeaderlist li a {
color: var(--color-dark);
}
.header:hover .leftHeaderlist li a {
color: var(--color-dark);
}
.leftHeaderlist li img {
width: 24px;
}

.leftHeaderlist li i {
font-size: 1.2rem;
}

.rightHeaderlist {
align-items: center;
display: flex;
gap: 20px;
list-style: none;
margin: 0;
padding: 0;
}

.rightHeaderlist li {
display: inline-block;
position: relative;
}

.rightHeaderlist li a {
color: var(--color-white) !important;
}

.rightHeaderlist li img {
width: 24px;
}

@media only screen and (max-width:968px) {
.leftHeaderlist {
gap: 15px;
}

.rightHeaderlist {
gap: 15px;
}
}

.logo-transition {
-webkit-transition: all .6s ease-in-out;
transition: all .6s ease-in-out;
}

.logo-transition-stickly {
-webkit-transform: translateY(-15rem) scale(0.9);
transform: translateY(-15rem) scale(0.9);
}

/*=========================================================*/
.fixed-header .mlogo {
display: block;
}
.mlogo img {
width: 150px;
height: auto;
}
.fixed-head .mlogo {
display: none;
}
.mlogofix {
display: none;
}
.mlogofix img {
width: 100px;
height: auto;
}
.fixed-head .mlogofix {
display: block;
}
.fixed-head .leftHeaderlist li a {
color: var(--color-dark);
}
.fixed-head .rightHeaderlist li a {
color: var(--color-dark) !important;
}
.innerHeader .mlogo {
display: none;
}
.innerHeader .mlogofix {
display: block;
}
.mheader.innerHeader .rightHeaderlist li a {
color: var(--color-dark) !important;
}
/*=========================================================*/
.dropdown-toggle::after {
border: 0px !important;
}

.dropdown-hover:hover>.dropdown-menu {
display: inline-block;
}

.dropdown-hover>.dropdown-toggle:active {
pointer-events: none;
}

.submenu {
border: 0px !important;
background: var(--color-white) !important;
margin: 0 !important;
min-width: 15rem !important;
padding: 0 !important;
border-radius: 0px !important;
}

.submenu .dropdown-item {
display: block;
width: 100%;
padding: 7px 15px;
clear: both;
font-weight: 400;
text-align: inherit;
text-decoration: none;
white-space: nowrap;
background-color: transparent;
border: 0;
font-size: 1rem;
}

.submenu .dropdown-item:hover {
background: var(--color-white) !important;
color: var(--color-primary) !important;
}

.mega-dropdown {
position: static !important;
}

.mega-menu {
width: 100%;
left: 0;
border: 0px !important;
margin: 0 !important;
padding: 25px 0px !important;
background: var(--color-white) !important;
border-radius: 0px !important;
border-top: 1px dashed var(--color-gray-200) !important;
}

.mega-menu .container {
display: block !important;
}

.mega-menu-imgbox {
width: 100%;
position: relative;
}

.mega-menu-imgbox img {
width: 100%;
}

.mega-menu-imgbox-content {
padding: 10px 0px;
width: 100%;
}

.menuiMG {
width: 100%;
position: relative;
text-align: center;
}

.menuiMG .menuiMGMedia {
position: relative;
margin-bottom: 0;
transition: box-shadow 0.3s;
width: 100%;
height: 300px;
text-align: center;
}

.menuiMG img {
display: block;
width: 100%;
height: 100%;
transition: all 0.5s;
object-fit: cover;
}

.menuiMG .menuiMGContent {
position: relative;
background-color: #fff;
padding-top: 1rem;
padding-bottom: 1rem;
}

.menuiMGContent a {
display: block;
color: var(--color-dark);
}

.menuiMGContent h2 {
font-size: 16px;
}

.mlist {
width: 100%;
position: relative;
}

.mlist ul {
margin: 0;
padding: 0;
list-style: none;
}

.mlist ul li {
display: inline-block;
width: 100%;
position: relative;
}

.mlist ul li a {
color: var(--color-dark);
display: inline-block;
line-height: 30px;
font-size: 0.9rem;
}

.mlist ul li a:hover {
color: var(--color-secondary);
}

.mlist .title {
color: var(--color-dark);
}

.mlist .title a {
color: var(--color-dark);
}

.searchIcon::after {
display: none;
}

.cartToggle::after {
display: none;
}

/*=========================================================*/
.cartSummaryBox {
width: 100%;
position: relative;
padding: 15px 25px;
border: 1px solid var(--color-dark);
}

.cartSummaryBox ul {
margin: 0;
padding: 0;
list-style: none;
}

.cartSummaryBox ul li {
display: flex;
align-items: center;
justify-content: space-between;
padding: 10px 0px;
}

@media screen and (max-width:968px) {
.cartSummaryBox {
padding: 15px;
border: 0px;
position: fixed;
bottom: 0;
width: 100%;
left: 0;
border-top: 1px solid #e1e1e1;
background: #fff;
z-index: 999;
}
}

/*=========================================================*/
.shop-table {
text-align: left;
width: 100%;
border-collapse: collapse;
}

.shop-table tbody {
border-bottom: 1px solid #e1e1e1
}

.shop-table td {
padding: 1.5rem 1rem 1.5rem 0;
border-top: 1px solid #e1e1e1;
font-size: 14px;
vertical-align: top;
}

.shop-table th {
padding: 0.3rem 0 0.3rem;
font-size: 16px;
font-weight: 600;
line-height: 1.875;
text-transform: uppercase;
color: #222
}

.shop-table img {
display: block;
max-width: 100px
}

.shop-product-img {
width: 100px;
}

.shop-table .product-thumbnail-shap {
width: 100px;
display: block;
overflow: hidden;
position: relative;
}

.shop-table .product-thumbnail-shap::before {
content: "";
display: block;
padding-top: 100%;
}

.shop-table .product-thumbnail {
height: 100%;
left: 0;
position: absolute;
top: 0;
transition: box-shadow .3s;
width: 100%;
background: #fdfafa;
}

.shop-table .product-thumbnail img {
height: 100%;
object-fit: cover;
width: 100%;
mix-blend-mode: darken;
transition: all 0.5s;
}

.shop-table .product-remove {
display: flex;
align-items: center;
justify-content: center;
text-align: center;
font-size: 0.8rem;
width: 1.7rem;
height: 1.7rem;
border: 1px solid #ccc;
background-color: #fff;
color: #222;
}

.shop-table .product-name {
white-space: normal;
color: #000
}

.shop-table td.product-remove {
padding-right: 0;
width: 23px
}

.shop-table .product-price {
display: table-cell;
padding: 1.5rem 0rem 1.5rem 0;
}

.shop-table .product-name {
font-size: 18px;
width: 300px;
}

.priceTotalTable {
display: flex;
flex-direction: column;
justify-content: flex-end;
align-items: flex-end;
gap: 10px;
}

/*=========================================================*/
.msprofile {
width: 100%;
position: relative;
padding: 10px 15px;
border-bottom: 1px solid var(--color-gray-200);
}

.msprofile .msprofile-media {
width: 60px;
height: 60px;
overflow: hidden;
background: var(--color-gray-100);
border-radius: 50px;
}

.msprofile .msprofile-media img {
width: 60px;
height: 60px;
object-fit: cover;
}

.msprofile .msprofile-content {
flex: 1;
padding-left: 15px;
}

.msprofile-arrow {
margin-left: auto;
}

.sidemenu {
width: 100%;
position: relative;
padding-top: 15px;
}

.sidemenu ul {
margin: 0;
padding: 0;
list-style: none;
}

.sidemenu ul li {
display: inline-block;
width: 100%;
position: relative;
font-size: 16px;
}

.sidemenu ul li .sub-menu-inner {
display: flex;
align-items: center;
justify-content: space-between;
padding: 10px 15px;
}

.sidemenu ul li .sub-menu-inner img {
height: auto;
}

.sub-menu-inner .right {
width: 50px;
text-align: right;
}

.sidemenu ul li a {
-webkit-transition: 0.2s linear;
-moz-transition: 0.2s linear;
-ms-transition: 0.2s linear;
-o-transition: 0.2s linear;
transition: 0.2s linear;
}

.sidemenu ul li ul {
background: var(--color-gray-100);
}

.sidemenu ul li ul .menu_color {
background: var(--color-gray-300);
}

.sidemenu ul li ul li a {
display: flex;
align-items: center;
font-size: 16px;
width: 100%;
position: relative;
}

.mslist {
margin: 0;
padding: 0;
list-style: none;
}

.mslist ul {
margin: 0;
padding: 0;
list-style: none;
}

.mslist ul li {
display: flex;
align-items: center;
height: 35px;
padding: 0px 15px;
font-size: 16px;
}

/*=========================================================*/
.blogSubHeader {
width: 100%;
position: relative;
background: rgb(226, 216, 215, 0.5);
}
.blogSubHeader .blogSubHeaderContent {
padding: 100px;
}
.blogSubHeader h1 {
font-size: 24px;
margin-bottom: 20px;
}
.catlist {
margin: 0;
padding: 0;
list-style: none;
display: flex;
align-items: center;
gap: 5px;
}
.catlist li {
display: inline-block;
}
.catlist li a {
display: inline-block;
padding: 3px 15px 0px;
border-radius: 50px;
background: var(--color-white);
font-size: 14px;
}
@media screen and (max-width:968px) {
.blogSubHeader .blogSubHeaderContent {
padding: 30px;
} 
}
/*=========================================================*/
.calendarMain {
color: hsla(0, 0%, 7%, .75) !important;
font-size: 14px !important;
height:auto;
max-width: 100%;
padding: 0rem 3rem;
margin: 0 auto;
}

.calendarSchedular {
display: grid;
gap: 28px;
grid-template-columns: repeat(2, 1fr);
}

.calendarSection {
position: relative;
}

.react-calendar__navigation button:enabled:focus,
.react-calendar__navigation button:enabled:hover {
background-color: inherit !important
}

.calendarSection .appicalendar {
background-color: initial;
border: none;
font-family: inherit !important;
height: 100%;
margin-bottom: 12px;
width: 100%
}

.calendarSection .react-datepicker__month-container {
float: none
}

.calendarSection .react-datepicker__current-month {
font-size: 14px;
font-weight: 400
}

.calendarSection .react-datepicker__header {
background-color: initial;
border-bottom: none;
padding-bottom: 0;
padding-top: 0
}

.calendarSection .react-datepicker__day {
align-items: center;
display: inline-flex;
height: 42px;
justify-content: center;
width: 42px
}

.calendarSection .react-datepicker__navigation-icon:before {
border-color: #525252;
border-style: solid;
border-width: 2px 2px 0 0;
height: 10px;
top: 13px;
width: 10px
}

.calendarSection,
.appicalendar {
margin-bottom: 12px
}

.appicalendar {
border: none;
max-width: 350px;
width: 100%;

@media screen and (max-width:780px) {
margin-left: auto;
margin-right: auto
}

button {
position: relative
}
}

.react-calendar__navigation button[aria-label=navigation] {
pointer-events: none
}

.react-calendar__navigation__arrow {
position: relative
}

.react-calendar__navigation__arrow:before {
border-right: 2px solid #525252;
border-top: 2px solid #525252;
content: "";
height: 10px;
position: absolute;
top: 16px;
width: 10px
}

.react-calendar__navigation button:disabled {
background-color: inherit !important
}

.react-calendar__navigation button:disabled:before {
border-right: 2px solid #8f8b8b;
border-top: 2px solid #8f8b8b
}

.react-calendar__month-view__days {
row-gap: 7px
}

.react-calendar__navigation__next-button:before {
right: 19px;
-webkit-transform: rotate(45deg);
transform: rotate(45deg)
}

.react-calendar__navigation__prev-button:before {
left: 19px;
-webkit-transform: rotate(-135deg);
transform: rotate(-135deg)
}

.calendarSection .react-calendar__tile:disabled {
background-color: inherit
}

.calendarSection .react-calendar .react-calendar__month-view__weekdays,
.calendarSection .react-calendar button {
color: #1b1f28;
font-size: 14px;
font-weight: 400;
line-height: 20px;
width: auto
}

.calendarSection .react-calendar abbr[title] {
text-decoration: none !important
}

.calendarSection .react-calendar__month-view__weekdays {
font-weight: 400;
text-transform: capitalize
}

.calendarSection .react-calendar__tile--active,
.calendarSection .react-calendar__tile--active:enabled:focus,
.calendarSection .react-calendar__tile--active:enabled:hover {
background: inherit;
border-radius: 50%;
color: #fff !important;
overflow: visible !important;
position: relative;
z-index: 100
}

.calendarSection .react-calendar__tile--active:before,
.calendarSection .react-calendar__tile--active:enabled:focus:before,
.calendarSection .react-calendar__tile--active:enabled:hover:before {
background-color: #1b1f28;
border-radius: 50%;
content: "";
height: 42px;
left: 50%;
position: absolute;
top: 50%;
-webkit-transform: translate(-50%, -50%);
transform: translate(-50%, -50%);
width: 42px;
z-index: -1
}

.calendarSection .react-calendar__tile--now:enabled:focus,
.calendarSection .react-calendar__tile--now:enabled:hover,
.calendarSection .react-calendar__tile:enabled:focus,
.calendarSection .react-calendar__tile:enabled:hover {
background-color: inherit;
outline: none !important
}

.calendarSection .react-calendar__tile--now {
background: none;
color: #fff
}

.calendarSection .react-calendar__navigation__next2-button,
.calendarSection .react-calendar__navigation__prev2-button {
display: none
}

.schedule_time_wrapper {
display: flex;
flex-direction: column;
gap: 12px;
height: 385px;
margin-bottom: 30px;
overflow-y: auto;
padding-bottom: 5px;
padding-right: 10px
}

.schedule_time_wrapper.auto_heigth {
height: auto !important
}

.schedule_time_wrapper.selects {
margin-bottom: 0
}

.schedule_time_wrapper .time_item.time_item_selected {
grid-gap: 12px;
display: grid;
gap: 12px;
grid-template-columns: repeat(1, 1fr)
}

.schedule_time_wrapper .time_item .item {
border: 1px solid #d3d9e5;
border-radius: 5px;
cursor: pointer;
padding: 12px;
text-align: center
}

.schedule_time_wrapper .time_item .item.item_selected {
background-color: #d3d9e54d
}

.schedule_time_wrapper .time_item .chousen {
align-items: center;
background-color: #1b1f28;
border-radius: 5px;
color: #fff;
cursor: pointer;
display: flex;
justify-content: center;
padding: 12px
}

.react-calendar__tile:disabled abbr {
color: #b3b6bd !important;
}

.scheduleForm {
width: 100%;
position: relative;
}

.scheduleForm .backbutton {
background: transparent;
padding: 0;
display: flex;
align-items: center;
border: 0;
gap: 5px;
font-size: 16px;
margin-bottom: 20px;
}

.scheduleFormInfo {
background: var(--color-gray-200);
padding: 20px;
border-radius: 10px;
margin-bottom: 20px;
}
@media screen and (max-width:968px) {
.calendarMain{
padding: 0;
}
}
/*=========================================================*/
/*=========================================================*/
/*=========================================================*/
/*=========================================================*/
/*=========================================================*/
/*=========================================================*/
/*=========================================================*/
.footer {
width: 100%;
position: relative;
background: var(--color-secondary);
color: var(--color-white);
}

.footer .footerMiddle {
padding: 60px 0px;
width: 100%;
position: relative;
color: var(--color-white);
}

.footer .footerBottom {
padding: 40px 0px;
width: 100%;
position: relative;
color: var(--color-white);
border-top: 1px solid rgb(226, 216, 215, 0.2);
margin-bottom: 50px;
}

.footerBottom .container,
.footerBottom .container-fluid {
display: flex;
align-items: center;
}

.footerBottom .footerBottomLeft,
.footerBottomCenter,
.footerBottomRight {
flex: 1;
}

.footerBottomLeft,
.footerBottomRight {
display: flex;
}

.footerTitle {
font-size: 18px;
margin-bottom: 20px;
color: var(--color-white) !important;
font-weight: 600 !important;
font-family: var(--font-primary) !important;
}

.footerList {
margin: 0;
padding: 0;
list-style: none;
}

.footerList li {
display: inline-block;
width: 100%;
position: relative;
}

.footerList li a {
display: inline-block;
color: var(--color-white) !important;
font-size: 1rem;
line-height: 28px;
}

.footerSocial {
margin: 0;
padding: 0;
list-style: none;
display: flex;
align-items: center;
gap: 10px;
}

@media only screen and (max-width:968px) {
.footerSocial {
justify-content: center;
}
}

.footerSocial li a {
display: flex;
align-items: center;
justify-content: center;
width: 30px;
height: 30px;
border: 1px solid var(--color-gray-100);
border-radius: 50%;
color: var(--color-white);
}

.footer-logo {
display: inline-block;
width: 100%;
}

.footer-logo img {
width: 225px;
}

@media only screen and (max-width:968px) {
.footer .footerMiddle {
padding: 40px 0px 20px;
}

.footer .footerBottom {
padding: 10px;
font-size: 13px;
}

.footerList li a {
color: var(--color-dark);
}
}

.fcontactlist {
width: 100%;
position: relative;
}

.fcontactlist ul {
margin: 0;
padding: 0;
list-style: none;
}

.fcontactlist ul li {
display: flex;
align-items: center;
gap: 5px;
margin-bottom: 15px;
padding-right:65px;
}

.fcontactlist ul li a {
display: flex;
align-items: center;
gap: 5px;
color: var(--color-white);
}

/*=========================================================*/
.footeraccord .accordion-button{
font-family: var(--font-primary) !important;
}
.footeraccord .accordion-button:not(.collapsed) {
background: var(--color-primary) !important;
box-shadow: none !important;
color: var(--color-dark);
}

.footeraccord .accordion-item {
border: 0;
}

.accordion-button:focus {
outline: 0;
box-shadow: none;
}

.footeraccord .footerList li a {
color: var(--color-dark) !important;
}

.footeraccord .fcontactlist ul li a {
color: var(--color-dark) !important;
}

/*=========================================================*/
.heroSlider {
width: 100%;
position: relative;
}
.heroSlider .swiper-arrow {
display: flex;
align-items: center;
justify-content: space-between;
position: absolute;
width: 100%;
top: 50%;
z-index: 9;
left: 0;
}
.heroSlider-content {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
display: flex;
align-items: center;
padding-top: 200px;
color: #666;
}
.heroSlider .swiper-arrow .prev-arrow {
left: 50px;
position: absolute;
}
.heroSlider .swiper-arrow .next-arrow {
right: 50px;
position: absolute;
}
.heroSlider .swiper-arrow img {
height: auto !important;
}
.video-fullscreen-wrap {
height: 100vh;
overflow: hidden;
}
.video-fullscreen-video {
height: 100%;
}
.video-fullscreen-wrap video {
width: 100vw;
height: auto;
min-width: 100%;
min-height: 100%;
-o-object-fit: cover;
object-fit: cover;
}
.video-fullscreen-wrap .v-content {
opacity: 1;
position: absolute;
bottom: 0;
left: 0;
right: 0;
width: 100%;
height: 100%;
margin: 0;
box-sizing: border-box;
text-align: center;
display: -webkit-box;
display: -ms-flexbox;
display: flex;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
z-index: 4;
}
.media-fullscreen-wrap {
height: 100vh;
overflow: hidden;
}
.media-fullscreen-img {
height: 100%;
}
.media-fullscreen-wrap img {
width: 100%;
height: auto;
min-width: 100%;
min-height: 100%;
-o-object-fit: cover;
object-fit: cover;
}
.media-fullscreen-wrap .m-content {
opacity: 1;
position: absolute;
bottom: 0;
left: 0;
right: 0;
width: 100%;
height: 100%;
margin: 0;
box-sizing: border-box;
text-align: center;
display: -webkit-box;
display: -ms-flexbox;
display: flex;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
z-index: 4;
}
@media only screen and (max-width:968px) {
.media-fullscreen-wrap {
height: 600px;
overflow: hidden;
}
}
/*=========================================================*/
.videoSec {
width: 100%;
position: relative;
overflow: hidden;
display: inline-block;
line-height: 0px;
}

.videoSec video {
width: 100%;
height: 100%;
object-fit: cover;
}

.imgSec {
width: 100%;
position: relative;
overflow: hidden;
display: inline-block;
}

.imgSec img {
width: 100%;
}

/*=========================================================*/
.testimonialSwiper {
position: relative;
}
.testimonialbox {
width: 100%;
position: relative;
padding: 15px;
border-radius: 5px;
display: flex;
gap: 15px;
border: 1px solid var(--color-gray-100);
align-items: center;
}
.testimonialbox .testimonialboxMedia {
width: 300px;
height: auto;
overflow: hidden;
position: relative;
}
.testimonialboxMedia img {
width: 100%;
height: 100%;
object-fit: cover;
}
.testimonialboxContent {
text-align: center;
position: relative;
}
.testimonialboxContent .text {
font-style: italic;
color: var(--color-gray-500);
padding: 25px;
}
@media only screen and (max-width:968px) {
.testimonialbox {
flex-direction: column;
}
.testimonialbox .testimonialboxMedia{
width: 100px;
}
}
/*=========================================================*/
.testimonialSwiper .swiper-arrow {
display: flex;
align-items: center;
justify-content: space-between;
position: absolute;
width: 100%;
top: 50%;
z-index: 9;
left: 0;
}

.testimonialSwiper .swiper-arrow .prev-arrow,
.swiper-arrow .next-arrow {
width: 42px;
height: 42px;
background: var(--color-gray-100);
display: flex;
align-items: center;
justify-content: center;
border-radius: 50%;
}

.testimonialSwiper .swiper-arrow i {
font-size: 26px;
margin-top: 3px;
}

.testimonialSwiper .swiper-arrow .prev-arrow {
left: -20px;
position: absolute;
}

.testimonialSwiper .swiper-arrow .next-arrow {
right: -20px;
position: absolute;
}

.testimonialSwiper .swiper-arrow img {
height: auto !important;
}

@media only screen and (max-width:968px) {
.testimonialSwiper .swiper-arrow .prev-arrow {
left: -10px;
position: absolute;
}

.testimonialSwiper .swiper-arrow .next-arrow {
right: -10px;
position: absolute;
}
}

.swiper-pagination-bullet-active {
background: var(--color-primary) !important;
}

/*=========================================================*/
.videoSwiper {
width: 100%;
position: relative;
}

.videoSwiper .swiper-arrow {
display: flex;
align-items: center;
justify-content: space-between;
position: absolute;
width: 100%;
top: 50%;
z-index: 9;
left: 0;
}

.videoSwiper .swiper-arrow .prev-arrow,
.swiper-arrow .next-arrow {
width: 42px;
height: 42px;
background: var(--color-gray-100);
display: flex;
align-items: center;
justify-content: center;
border-radius: 50%;
}

.videoSwiper .swiper-arrow i {
font-size: 26px;
margin-top: 3px;
}

.videoSwiper .swiper-arrow .prev-arrow {
left: -20px;
position: absolute;
}

.videoSwiper .swiper-arrow .next-arrow {
right: -20px;
position: absolute;
}

.videoSwiper .swiper-arrow img {
height: auto !important;
}

@media only screen and (max-width:968px) {
.videoSwiper .swiper-arrow .prev-arrow {
left: -10px;
position: absolute;
}

.videoSwiper .swiper-arrow .next-arrow {
right: -10px;
position: absolute;
}
}

/*=========================================================*/
.product {
position: relative;
overflow: hidden;
width: 100%;
}

.product .product-media-shap {
display: block;
overflow: hidden;
position: relative;
}

.product .product-media-shap::before {
content: "";
display: block;
padding-top: 125%;
}

.product .product-media {
height: 100%;
left: 0;
position: absolute;
top: 0;
transition: box-shadow .3s;
width: 100%;
background: #fdfafa;
}

.product .product-media:hover img:first-child {
opacity: 0
}

.product .product-media:hover img:last-child {
opacity: 1
}

.product .product-media img {
height: 100%;
object-fit: cover;
width: 100%;
mix-blend-mode: darken;
transition: all 0.5s;
}

.product .product-media img:last-child {
position: absolute;
opacity: 0;
left: 0;
right: 0;
top: 0;
left: 0;
object-fit: cover
}

.product .product-media img:first-child {
position: relative;
opacity: 1
}

.product .product-details {
position: relative;
padding-top: 1rem;
padding-bottom: 1rem;
}

.text-center .product-details {
padding-left: 1rem;
padding-right: 1rem;
}

.product .product-details .product-name {
margin-bottom: 0.5rem;
font-size: 18px;
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
line-height: 24px;
}

.product .product-details .product-name a {
display: inline-block;
}

.product-price {
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
display: flex;
align-items: center;
flex-wrap: wrap;
margin-bottom: 0.5rem;
font-size: 16px;
gap: 10px;
font-weight: 500;
}

.product-price .new-price {
text-decoration: none;
}

.product-price .old-price {
color: #aaa;
}

.product.tx-center .product-price {
justify-content: center;
}

.product-action-vertical {
position: absolute;
top: 10px;
right: 10px;
transition: opacity 0.3s, visibility 0.3s;
}

.btn-product-icon {
display: flex;
align-items: center;
justify-content: center;
width: 34px;
height: 34px;
margin-bottom: 0.5rem;
border: 0px solid #e1e1e1;
border-radius: 50%;
background-color: rgba(255, 255, 255, 0.5);
color: #999;
font-size: 16px;
font-weight: 700;
transition: border-color 0.3s, color 0.3s, background-color 0.3s
}

.btn-product-icon:hover {
border-color: var(--color-secondary);
color: #fff !important;
background-color: var(--color-secondary)
}

.product-action-vertical i {
font-size: 1.2rem;
margin-top: 3px;
}

.product-label {
position: absolute;
left: 10px;
top: 10px;
display: flex;
align-items: center;
gap: 10px;
}

.product-label span {
padding: 5px 15px 1px;
background: var(--color-white);
border-radius: 20px;
display: inline-block;
font-size: 14px;
}

.product-label .label-new {
background: var(--color-white);
}

.product-label .label-sale {
background: #d26e4b
}

.product-label .label-top {
background: #26c
}

.product-label .label-stock {
background: #999
}

.product .addcartbutton {
position: absolute;
z-index: 9;
bottom: 5px;
right: 10px;
background: transparent;
border: 0;
font-size: 1.3rem;
}

.product.productHorizantional {
display: flex;
gap: 15px;
}

.product.productHorizantional .product-media-shap {
width: 100px;
}

.product.productHorizantional .product-details {
padding: 0px;
flex: 1;
display: flex;
flex-direction: column;
justify-content: space-between;
margin: 0;
}

.product.productHorizantional .product-details-header {
width: 100%;
align-items: flex-start;
}

.product.productHorizantional .product-details .product-name {
font-size: 16px;
line-height: 26px;
}

.product.productHorizantional .product-price {
flex-direction: column;
align-items: flex-end;
justify-content: flex-end;
gap: 0px;
font-size: 14px;
}

.product.productHorizantional .addcartbutton {
position: relative;
font-size: 16px;
}

@media screen and (max-width:968px) {
.product .product-details .product-name {
margin-bottom: 0.5rem;
font-size: 14px;
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
line-height: 21px;
}
}

/*=========================================================*/
.variationAccord {
width: 100%;
position: relative;
margin-top: 20px;
}

.variationAccord .accordion {
border: 0px !important;
border-radius: 0px;
}

.variationAccord .accordion-header {
font-family: var(--font-primary) !important;
}

.accordion-header .productVarationMeta:hover {
background: transparent;
}

.variationAccord .accordion-item {
border: 0px;
border-bottom: 1px solid var(--color-gray-200);
margin-bottom: 20px;
border-radius: 0px;
}

.variationAccord .accordion-button {
padding: 10px 0px;
box-shadow: none !important;
background: transparent !important;
color: var(--color-dark);
}

.variationAccord .accordion-body {
padding: 0px;
}

.productVarationMeta {
display: flex;
align-items: center;
gap: 10px;
cursor: pointer;
padding: 5px 0px;
}

.productVarationMeta:hover {
background: rgb(226, 216, 215, 0.3);
}

.productVarationMeta span {
margin-top: -3px;
}

.productVarationMeta .color {
width: 20px;
height: 20px;
border-radius: 50%;
transition: 0.3s;
}

.productVarationMeta .color.active {
box-shadow: inset 0 0 0 2px #fff;
border: 1px solid var(--color-primary);
}

.productVarationMeta .color:hover {
box-shadow: inset 0 0 0 2px #fff;
border: 1px solid var(--color-primary);
}

.productVarationMeta .text {
width: 30px;
height: 30px;
border-radius: 2px;
transition: 0.3s;
border: 1px solid var(--color-gray-300);
display: flex;
align-items: center;
justify-content: center;
padding-top: 4px;
}

.productVarationMeta .text.active {
box-shadow: inset 0 0 0 2px #fff;
border: 1px solid var(--color-primary);
}

.productVarationMeta .text:hover {
box-shadow: inset 0 0 0 2px #fff;
border: 1px solid var(--color-primary);
}

.productVarationMeta .swatch {
width: 20px;
height: 20px;
border-radius: 2px;
transition: 0.3s;
border: 1px solid var(--color-gray-300);
display: flex;
align-items: center;
justify-content: center;
}

.productVarationMeta .swatch img {
width: 20px;
height: 20px;
object-fit: cover;
}

.productVarationMeta .swatch.active {
box-shadow: inset 0 0 0 2px #fff;
border: 1px solid var(--color-primary);
}

.productVarationMeta .swatch:hover {
box-shadow: inset 0 0 0 2px #fff;
border: 1px solid var(--color-primary);
}

/*=========================================================*/
.sidebarWidgetMain{
display: block;
}
.sidebar .sidebarInner {
width: 100%;
position: relative;
left: 0;
top: 0;
}
.sidebarWidget {
background: var(--color-white);
position: relative;
width: 100%;
border-bottom: 1px solid var(--color-gray-200);
padding-bottom: 25px;
margin-bottom: 25px;
}
.sidebarWidget .sidebarWidgetHeader {
width: 100%;
display: flex;
align-items: center;
justify-content: space-between;
position: relative;
margin-bottom: 15px;
}
.sidebarWidgetHeader h5 {
margin-bottom: 0px;
}
.slist {
margin: 0;
padding: 0;
list-style: none;
}
.slist li {
display: inline-block;
width: 100%;
position: relative;
line-height: 30px;
font-size: 14px;
}
.slist li a {
display: inline-block;
width: 100%;
position: relative;
}
.clearfilter {
margin: 0;
padding: 0;
list-style: none;
display: flex;
align-items: center;
gap: 7px;
flex-wrap: wrap;
}
.clearfilter li {
cursor: pointer;
display: inline-block;
position: relative;
}
.clearfilter li .clearfilterbox {
display: flex;
align-items: center;
padding: 4px 10px 3px;
border-radius: 50px;
border: 1px solid var(--color-gray-200);
justify-content: space-between;
font-size: 14px;
}
.clearfilterbox span {
padding-right: 10px;
}
.sizelist {
margin: 0;
padding: 0;
list-style: none;
display: flex;
align-items: center;
gap: 7px;
flex-wrap: wrap;
}
.sizelist li {
cursor: pointer;
padding: 7px 10px 5px;
border: 1px solid var(--color-gray-200);
}
.mslist {
margin: 0;
padding: 0;
list-style: none;
}
.mslist li {
display: flex;
align-items: center;
gap: 10px;
height: 40px;
width: 100%;
position: relative;
}
.mslist li .colorb {
width: 24px;
height: 24px;
border-radius: 50%;
overflow: hidden;
}
.sidebarWidgetTop{
display:flex;
align-items: center;
gap: 10px;
}
.sidebarWidgetTop .filterSidebar{
display: none;
border-bottom: 1px solid var(--color-gray-200);
padding-bottom: 25px;
margin-bottom: 25px;
cursor: pointer;
}
.filterSidebar .filterSidebar-inner{
border: var(--bs-border-width) solid var(--bs-border-color);
border-radius: var(--bs-border-radius);
height: 58px;
width: 100px;
display: flex;
align-items: center;
justify-content: center;
gap: 5px;
}
@media only screen and (max-width:968px) {
.sidebarWidgetMain{
display: none;
}
.sidebarWidgetTop .filterSidebar{
display: flex;
}
}
/*=========================================================*/
.single-product {
width: 100%;
position: relative;
}

.single-product .product-details {
position: sticky;
top: 50px;
left: 0;
max-width: 26.8125rem;
padding-top: 2.5rem;
padding-bottom: 2.5rem;
margin-left: 100px;
}

.single-product .product-details-header {
display: flex;
justify-content: space-between;
align-items: center;
}

.single-product .product-details .product-name {
font-size: 24px;
line-height: 34px;
}

.single-product .wishlist {
background: transparent;
border: 0;
font-size: 1.7rem;
}

.single-product .product-gallery {
width: 100%;
position: relative;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
gap: 5px;
}

.product-gallery img {
width: 100%;
}

.single-product .product-details .product-footer {
display: flex;
align-items: center;
justify-content: space-between;
}

.productTabs .nav-tabs {
border: 0px;
gap: 25px;
}

.productTabs .nav-tabs .nav-link {
color: var(--color-dark);
padding: 0px !important;
border: 0px !important;
}

.productTabs .nav-tabs .nav-link::after {
display: none;
}

.productTabs .nav-tabs .nav-link:hover {
border: 0px;
}

.productTabs .nav-tabs .nav-link.active {
border: 0px;
border-bottom: 2px solid var(--color-secondary) !important;
}

.productTabs .tab-content>.tab-pane {
padding: 15px 0px;
}

.productTabs .tab-content>.tab-pane ul {
margin: 0;
padding: 0px 15px;
}

.single-product .sl-spinner3 {
height: 30px;
}

@media only screen and (max-width:968px) {
.single-product .product-details {
position: relative;
top: 0;
left: 0;
padding: 15px;
max-width: 100%;
margin: 0;
}

.single-product .product-details .product-name {
font-size: 18px;
line-height: 28px;
}
}

.gallerySlider {
position: relative;
width: 100%;
}

.gallerySlider img {
width: 100%;
}

.gallerySlider .swiper-arrow {
display: flex;
align-items: center;
justify-content: space-between;
position: absolute;
width: 100%;
top: 50%;
z-index: 9;
left: 0;
}

.gallerySlider .swiper-arrow .prev-arrow {
left: 5px;
position: absolute;
}

.gallerySlider .swiper-arrow .next-arrow {
right: 5px;
position: absolute;
}

.gallerySlider .swiper-arrow .prev-arrow,
.swiper-arrow .next-arrow {
width: 42px;
height: 42px;
background: var(--color-gray-100);
display: flex;
align-items: center;
justify-content: center;
border-radius: 50%;
}

.gallerySlider .swiper-arrow i {
font-size: 26px;
margin-top: 3px;
}

@media only screen and (max-width:968px) {
.gallerySlider {
position: relative;
width: 100%;
height: 50vh;
overflow: hidden;
}

.gallerySlider img {
width: 100%;
height: 100%;
object-fit: cover;
}
}

/*=========================================================*/
.vSlider {
width: 200px;
position: relative;
height: 650px;
display: flex;
align-items: center;
margin: 0 auto;
margin-top: 50px;
}

.vSlider .swiper {
width: 100%;
height: 100%;
}

.vSlider .swiper-arrow {
position: absolute;
top: 0;
right: 0;
display: flex;
flex-direction: column;
gap: 15px;
height: 100%;
align-items: center;
width: 100%;
}

.vSlider .swiper-arrow .prev-arrow {
position: absolute;
top: -50px;
}

.vSlider .swiper-arrow .next-arrow {
position: absolute;
bottom: -30px;
}

/*=========================================================*/
.profileSection {
position: relative;
}

.profile-container {
display: flex;
}

.profile-box {
flex-basis: calc(13.333%);
position: relative;
overflow: hidden;
height: 100%;
transition: all 0.6s ease-out;
position: relative;
display: flex;
align-items: flex-end;
}

.profile-box:first-child {
flex-basis: calc(75%);
}

.profile-box img {
width: 100%;
height: 550px;
object-fit: cover;
}

.profile-box:hover .profile-box-content {
opacity: 1;
transition: all 0.3s ease-out 0.6s;
left: 0;
}

.profile-box:last-child {
border: 0px;
}

.profile-container:hover .profile-box {
flex-basis: calc(15%);
}

.profile-container:hover .profile-box:hover {
flex-basis: calc(70%);
transition: all 0.4s ease-out;
z-index: 1;
}

.profile-title {
font-family: var(--font-secondary);
font-size: 62px;
text-transform: uppercase;
position: absolute;
top:55px;
z-index: 9;
left: 50px;
font-style: italic;
font-weight: 700;
color: var(--color-secondary);
letter-spacing: -0.2rem;
}
@media only screen and (max-width:968px) {
.profile-box img {
width: 100%;
height: 350px;
object-fit: cover;
}
}
/*=========================================================*/
.modal.left .modal-dialog,
.modal.right .modal-dialog {
position: fixed;
margin: auto;
width: 350px;
height: 100%;
-webkit-transform: translate3d(0%, 0, 0);
-ms-transform: translate3d(0%, 0, 0);
-o-transform: translate3d(0%, 0, 0);
transform: translate3d(0%, 0, 0);
}

.modal.left .modal-content,
.modal.right .modal-content {
height: 100%;
overflow-y: auto;
}

.modal.left .modal-body,
.modal.right .modal-body {
padding: 15px 15px 80px;
}

/*Right*/
.modal.right.fade .modal-dialog {
right: -350px;
-webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
-moz-transition: opacity 0.3s linear, right 0.3s ease-out;
-o-transition: opacity 0.3s linear, right 0.3s ease-out;
transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.fade.show .modal-dialog {
right: 0;
}

.modal-content {
border-radius: 0;
border: none;
}

/*=========================================================*/
.cartmodal.modal.left .modal-dialog,
.cartmodal.modal.right .modal-dialog {
position: fixed;
margin: auto;
width: 480px;
max-width: 100%;
height: 100%;
-webkit-transform: translate3d(0%, 0, 0);
-ms-transform: translate3d(0%, 0, 0);
-o-transform: translate3d(0%, 0, 0);
transform: translate3d(0%, 0, 0);
}

.cartmodal.modal.fade .modal-dialog {
transition: transform .3s ease-out;
transform: translate(0, 0px) !important;
}

.cartmodal.modal.right.fade .modal-dialog {
right: -480px;
-webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
-moz-transition: opacity 0.3s linear, right 0.3s ease-out;
-o-transition: opacity 0.3s linear, right 0.3s ease-out;
transition: opacity 0.3s linear, right 0.3s ease-out;
}

.cartmodal.modal.right.fade.show .modal-dialog {
right: 0;
}

@media only screen and (max-width:968px) {

.cartmodal.modal.left .modal-dialog,
.cartmodal.modal.right .modal-dialog {
position: fixed;
margin: auto;
width: 350px;
max-width: 100%;
height: 100%;
transform: translate3d(0%, 0, 0);
}

.cartmodal.modal.right.fade .modal-dialog {
right: -350px;
transition: opacity 0.3s linear, right 0.3s ease-out;
}

.cartmodal.modal.right.fade.show .modal-dialog {
right: 0;
}
}

.cartmodal .modal-content {
background: var(--color-white);
border: 0;
border-radius: 0;
}

.cartmodalRecomd {
background: #f1eee4;
height: 100%;
overflow-x: hidden;
overflow-y: auto;
position: fixed;
right: 480px;
width: 250px;
}

.cartmodalRecomdHeader {
padding: 25px;
width: 100%;
position: relative;
text-align: center;
}

.cartmodalRecomdBody {
padding: 15px 45px;
width: 100%;
position: relative;
}

/*=========================================================*/
.cartmodalBody {
padding: 25px;
width: 100%;
position: relative;
height: 100%;
}

.cartmodalHeader {
display: flex;
justify-content: space-between;
align-items: center;
border-bottom: 1px solid var(--color-gray-100);
padding-bottom: 15px;
}

.cart-close {
padding: 0;
background: transparent;
border: 0px;
}

.cartmodal .cartmodal-scroll {
height: calc(100% - 100px);
overflow-x: hidden;
width: 100%;
}

.cartmodal .cartmodal-footer {
background: var(--color-white);
bottom: 0;
left: 0;
padding: 25px;
position: fixed;
width: 100%;
z-index: 9999;
}

.cartmodal .cartmodal-total {
display: flex;
align-items: center;
justify-content: space-between;
padding: 10px 0px;
border-top: 1px solid var(--color-gray-200);
font-size: 16px;
}

.total-price {
font-size: 18px;
}

.cartProduct {
padding: 10px 0px;
width: 100%;
position: relative;
border-bottom: 1px dotted var(--color-gray-200);
}

@media screen and (max-width:968px) {
.cartmodalBody {
padding: 25px 15px;
}

.cartmodal .cartmodal-footer {
padding: 25px 15px 15px;
}
}

/*=========================================================*/
.cartProduct {
padding: 20px;
width: 100%;
position: relative;
border-bottom: 1px dotted var(--color-gray-200);
gap: 10px;
}

.cartProduct:last-child {
border-bottom: 0px;
}

.cartProduct .cartProductMedia-shap {
display: block;
overflow: hidden;
position: relative;
width: 90px;
height: 100px;
}

.cartProduct .cartProductMedia-shap::before {
content: "";
display: block;
padding-top: 100%;
}

.cartProduct .cartProductMedia {
height: 100%;
left: 0;
position: absolute;
top: 0;
transition: box-shadow .3s;
width: 100%;
background: #fdfafa;
}

.cartProductMedia img {
height: 100%;
object-fit: cover;
width: 100%;
mix-blend-mode: darken;
transition: all 0.5s;
}

.cartProduct .cartProductDetails {
flex: 1;
}

.cartProduct .title {
font-size: 16px;
margin-right: 25px;
margin-bottom: 5px;
line-height: 26px;
}

.cartProductRemove {
position: absolute;
top: 10px;
right: 0;
font-size: 18px;
}

.cartProduct-footer {
display: flex;
align-items: center;
justify-content: space-between;
}

.cartProduct-price {
display: flex;
flex-wrap: wrap;
font-size: 16px;
font-weight: 600;
line-height: 18px;
flex-direction: column;
}

.cartProduct-price .new-price {
text-decoration: none;
}

.cartProduct-price .old-price {
color: #aaa;
font-weight: 500 !important;
font-size: 14px;
}

.cartProductqty {
align-content: center;
border: 1px solid var(--color-gray-200);
border-radius: 5px;
display: flex;
height: 30px;
justify-content: space-between;
width: 70px;
font-size: 14px;
}

.cartProductqty span {
align-items: center;
display: flex;
height: 30px;
justify-content: center;
width: 100%;
}

@media screen and (max-width:968px) {
.cartProduct .title {
font-size: 14px;
margin-right: 25px;
margin-bottom: 5px;
line-height: 20px;
}
}

/*=========================================================*/
.subHeader {
position: relative;
width: 100%;
background: var(--color-light);
}
.subHeaderAccount {
padding: 40px 0px;
}
.subHeaderAccount .breadcrumb {
margin-bottom: 5px;
}
.subHeaderContent {
padding: 50px 0px;
z-index: 9;
position: relative;
}
.subHeader.subHeaderwithImg {
position: relative;
width: 100%;
height: 600px;
overflow: hidden;
display: flex;
align-items: flex-end;
}
.subHeaderbg {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
}
.subHeaderwithImg img {
width: 100%;
height: 100%;
object-fit: cover;
position: absolute;
top: 0;
left: 0;
}
.subHeaderwithImgContent {
position: absolute;
top: 0;
bottom: 0;
left: 0;
z-index: 9;
width: 100%;
height: 100%;
display: flex;
align-items: center;
justify-content: center;
color: var(--color-white);
}
@media screen and (max-width:968px) {
.subHeader.subHeaderwithImg {
height: 300px;
} 
.subHeader h1{
font-size: 2.5rem;
}
.subHeaderContent {
padding: 20px 0px;
}
.subHeaderContent p{
font-size: 14px;
}
}
/*=========================================================*/
.videoBox {
padding: 25px;
background: var(--color-light);
width: 100%;
position: relative;
}

.videoBox video {
width: 100%;
height: 100%;
}

/*=========================================================*/
.parallex-effect {
width: 100%;
position: relative;
height: 500px;
background-position: center !important;
background-size: cover !important;
background-repeat: no-repeat !important;
background-attachment: fixed !important;
}

/*=========================================================*/
.slideCatbox {
width: 100%;
position: relative;
display: block;
color: var(--color-white);
}

.slideCatbox .slideCatboxMedia {
width: 100%;
position: relative;
overflow: hidden;
}

.slideCatboxMedia img {
width: 100%;
height: 100%;
object-fit: cover;
}

.slideCatboxContent {
position: absolute;
bottom: 15px;
left: 0;
width: 100%;
z-index: 9;
left: 15px;
}

/*=========================================================*/
.blogList {
position: relative;
width: 100%;
margin-bottom: 20px;
color: var(--color-white);
}

.blogList .blogListMedia {
width: 100%;
position: relative;
overflow: hidden;
background: var(--color-gray-900);
}

.blogList .blogListMedia>div {
opacity: .8;
width: 100%;
padding-bottom: 130%;
background-size: cover;
background-position: center;
transform: scale(1.3);
transition: all 2s cubic-bezier(.25, 1, .5, 1);
}

.blogList .blogListContent {
pointer-events: none;
position: absolute;
bottom: 0;
left: 0;
padding: 20px 60px;
z-index: 2;
color: #fff;
text-align: center;
width: 100%;
}

.blogList .blogListContent .title {
font-size: 28px;
color: var(--color-white) !important;
}

.blogList .blogListContent .tdec {
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
}

.blog-link::after {
position: absolute;
top: 0;
right: 0;
bottom: 0;
left: 0;
z-index: 1;
pointer-events: auto;
content: "";
background-color: rgba(0, 0, 0, 0);
}

.blogList:hover .blogListMedia>div {
opacity: 1;
transform: scale(1)
}

.blogList:hover .blogListContent .title {
color: var(--color-dark) !important;
}

.blogList:hover .blogListContent {
color: var(--color-dark) !important;
}

/*=========================================================*/
.searchModal.modal.left .modal-dialog,
.searchModal.modal.right .modal-dialog {
position: fixed;
margin: auto;
width: 100%;
max-width: 100%;
height: 100%;
-webkit-transform: translate3d(0%, 0, 0);
-ms-transform: translate3d(0%, 0, 0);
-o-transform: translate3d(0%, 0, 0);
transform: translate3d(0%, 0, 0);
}

.searchModal.modal.fade .modal-dialog {
transition: transform .3s ease-out;
transform: translate(0, 0px) !important;
}

.searchModal.modal.right.fade .modal-dialog {
right: -480px;
-webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
-moz-transition: opacity 0.3s linear, right 0.3s ease-out;
-o-transition: opacity 0.3s linear, right 0.3s ease-out;
transition: opacity 0.3s linear, right 0.3s ease-out;
}

.searchModal.modal.right.fade.show .modal-dialog {
right: 0;
}

.searchModal .modal-content {
background: rgb(226, 216, 215, 0.7);
border: 0;
border-radius: 0;
}

.searchModal .searchClose {
position: fixed;
top: 10%;
right: 2%;
background: transparent;
border: 0;
color: var(--color-white);
font-size: 2rem;
}

.searchBox {
width: 100%;
height: 100%;
display: flex;
align-items: center;
justify-content: center;
}

.searchBox .searchBoxInner {
width: 900px;
margin: 0 auto;
display: flex;
align-items: center;
gap: 25px;
}

.searchBox input {
width: 100%;
height: 100px;
font-size: 5rem;
background: transparent;
border: 0px;
border-bottom: 1px solid var(--color-white);
color: var(--color-white);
flex: 1;
}

.searchBox .searchButton {
width: 65px;
height: 65px;
border-radius: 50%;
border: 1px solid var(--color-white);
background: transparent;
line-height: 0;
color: var(--color-white);
font-size: 2rem;
display: flex;
align-items: center;
justify-content: center;
}

@media only screen and (max-width: 968px) {
.searchBox .searchBoxInner {
width: 100%;
margin: 0 auto;
display: flex;
align-items: center;
gap: 15px;
padding: 25px;
}

.searchBox input {
height: 50px;
font-size: 1.7rem;
}

.searchBox .searchButton {
width: 42px;
height: 42px;
font-size: 1rem;
}

.searchModal .searchClose {
top: 2%;
}
}

/*=========================================================*/
.pagination {
justify-content: center;
margin: 0;
}

.pagination .page-link {
border: 0;
line-height: 24px;
padding: 10px 5px 5px;
color: var(--color-gray-500);
font-size: 14px;
margin: 0 15px;
}

.pagination .page-item.active .page-link {
background: transparent;
position: relative;
}

.pagination .page-item.active .page-link::after {
content: "";
border-bottom: 1px solid var(--color-gray-500);
display: inline-block;
position: absolute;
bottom: 0;
width: 100%;
left: 0;
}

/*=========================================================*/
.ordersection {
background-size: cover !important;
background-position: 100% !important;
background-repeat: no-repeat !important;
padding: 100px 0px;
}

.trakorderbox {
width: 900px;
margin: 0 auto;
display: flex;
align-items: center;
gap: 10px;
}

.trakorderbox input {
width: 100%;
height: 50px;
border: 1px solid var(--color-dark);
background: transparent;
padding: 15px;
color: var(--color-white);
}

@media only screen and (max-width: 968px) {
.trakorderbox {
flex-direction: column;
width: 100%;
padding: 25px;
}
}

/*=========================================================*/
.faqcatbodc {
position: relative;
background: url(../public/img/padmakshyashape.svg) no-repeat 50%;
background-size: contain;
min-height: 280px;
}

.faqcatbodc.active {}

.faqcatbodc .faqcatbodcContent {
margin-left: auto;
margin-right: auto;
display: flex;
min-height: 280px;
max-width: 160px;
flex-direction: column;
align-items: center;
justify-content: center;
padding-left: .375rem;
padding-right: .375rem;
gap: 40px;
text-align: center;
}

.faqcatbodcContent img {
width: 52px;
height: 52px;
}

.faqaccordation {
width: 100%;
position: relative;
}

.faqaccordation .accordion-item {
border: 1px solid var(--color-gray-500) !important;
border-radius: 0px !important;
}

.faqaccordation .accordion-button {
background: var(--color-white) !important;
box-shadow: none !important;
color: var(--color-dark) !important;
border-radius: 0px !important;
border-bottom: 0px solid var(--color-gray-400) !important;
}

/*=========================================================*/
.form-group {
width: 100%;
position: relative;
}

.form-group input {
width: 100%;
height: 50px;
border: 1px solid var(--color-gray-200);
padding: 15px 15px 10px 15px;
}

.form-group.country-input input {
padding-left: 80px;
}

.countrycode {
position: absolute;
top: 0;
left: 0;
width: 70px;
height: 50px;
line-height: 55px;
text-align: center;
cursor: pointer;
border: 1px solid var(--color-gray-200);
background: var(--color-white);
}

.form-group textarea {
width: 100%;
height: 70px;
border: 1px solid var(--color-gray-200);
padding: 15px 15px 10px 15px;
}

/*=========================================================*/
.loginrigisterbox {
padding: 50px 0px;
width: 100%;
position: relative;
max-width: 380px;
margin: 0 auto;
}

/*=========================================================*/
.whislistproductsec {
background: var(--color-white);
padding: 15px;
width: 100%;
position: relative;
}

.wpbox {
width: 100%;
position: relative;
display: flex;
border-bottom: 1px solid var(--color-gray-200);
padding-bottom: 15px;
margin-bottom: 15px;
}

.wpbox:last-child {
border-bottom: 0px;
padding-bottom: 0px;
margin-bottom: 0px;
}

.wpbox .wpboxMedia {
width: 175px;
margin-right: 15px;
}

.wpbox .wpboxMedia img {
width: 100%;
height: 100%;
object-fit: cover;
}

.wpbox .wpboxContent {
flex: 1;
}

.wpboxContentFooter {
display: flex;
align-items: center;
justify-content: space-between;
}

.wpboxContentHeader {
display: flex;
justify-content: space-between;
}

.wpboxMeta {
margin: 0;
padding: 0;
list-style: none;
}

.wpboxMeta li {
display: inline-block;
width: 100%;
font-size: 16px;
line-height: 26px;
}

.wpboxPrice {
font-size: 20px;
line-height: 20px;
display: flex;
flex-direction: column;
gap: 5px;
}

.wpboxPrice .new-price {
text-decoration: none;
font-weight: 500;
}

.wpboxPrice .old-price {
color: #aaa;
font-size: 16px;
font-weight: 400 !important;
}

.wpboxallproducts {
padding: 25px;
}

@media only screen and (max-width: 968px) {
.wpbox .wpboxMedia {
width: 90px;
margin-right: 10px;
height: 90px;
}

.wpboxContentHeader h4 {
font-size: 16px;
}

.wpboxMeta {
margin: 0;
padding: 0;
list-style: none;
line-height: 18px;
}

.wpboxMeta li {
display: inline-block;
width: 100%;
font-size: 13px;
line-height: 10px;
}

.wpboxPrice {
font-size: 14px;
line-height: 14px;
display: flex;
flex-direction: column;
gap: 0px;
}

.wpboxPrice .old-price {
color: #aaa;
font-size: 12px;
font-weight: 400 !important;
}
}

/*=========================================================*/
.noimg {
width: 100%;
height: 100%;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
padding: 50px;
}

/*=========================================================*/
.checkout-wapper {
position: fixed;
width: 100%;
height: 100%;
display: flex;
background: var(--color-white);
}

.checkout-wapper .left {
width: 50%;
position: relative;
padding: 50px 50px 50px 100px;
overflow-y: scroll;
}

.checkout-wapper .right {
width: 50%;
background: var(--color-gray-100);
position: relative;
padding: 50px 100px 50px 50px;
overflow: auto;
}

.rightCheckoutPanel {
background: var(--color-white);
padding: 25px;
width: 100%;
position: relative;
}

.checkoutProduct {
width: 100%;
position: relative;
border-bottom: 1px solid var(--color-gray-200);
padding-bottom: 15px;
margin-bottom: 15px;
display: flex;
}

.checkoutProduct .checkoutProductMedia {
width: 90px;
height: 90px;
overflow: hidden;
margin-right: 15px;
}

.checkoutProductMedia img {
width: 100%;
height: 100%;
object-fit: cover;
}

.checkoutProduct .checkoutProductContent {
flex: 1;
}

.checkoutProduct .title {
font-size: 16px;
margin-right: 25px;
margin-bottom: 5px;
}

.checkoutProductFooter {
display: flex;
align-items: center;
justify-content: space-between;
margin-top: 10px;
}

.checkoutProduct-price {
display: flex;
flex-wrap: wrap;
font-size: 16px;
font-weight: 600;
line-height: 18px;
flex-direction: column;
align-items: flex-end;
justify-content: flex-end;
}

.checkoutProduct-price .new-price {
text-decoration: none;
}

.checkoutProduct-price .old-price {
color: #aaa;
font-weight: 500 !important;
font-size: 14px;
}

.checkoutProductqty {
align-content: center;
border: 1px solid var(--color-gray-200);
border-radius: 5px;
display: flex;
height: 30px;
justify-content: space-between;
width: 70px;
font-size: 14px;
}

.checkoutProductqty span {
align-items: center;
display: flex;
height: 30px;
justify-content: center;
width: 100%;
}

.applyofferbox {
display: flex;
align-items: center;
gap: 10px;
border-bottom: 1px solid var(--color-gray-200);
padding-bottom: 15px;
margin-bottom: 15px;
}

.applyofferbox input {
width: 100%;
height: 52px;
border: 1px solid var(--color-gray-200);
padding: 15px;
}

.checkputPricebox {
margin: 0;
padding: 0;
list-style: none;
}

.checkputPricebox li {
display: flex;
align-items: center;
justify-content: space-between;
padding: 5px 0px;
font-size: 15px;
}

@media only screen and (max-width: 968px) {
.checkout-wapper {
flex-direction: column;
position: relative;
}

.checkout-wapper .left {
padding: 15px;
width: 100%;
}

.checkout-wapper .right {
padding: 15px;
width: 100%;
}
}

/*=========================================================*/
.checkoutLogo img {
width: 150px;
height: auto;
}

.checkoutStep {
display: flex;
align-items: center;
margin: 25px 0;
padding: 0;
list-style: none;
gap: 35px;
}

.checkoutStep li {
position: relative;
}

.checkoutStep li::after {
content: "\ea6e";
font-family: remixicon !important;
font-style: normal;
-webkit-font-smoothing: antialiased;
position: absolute;
right: -25px;
top: -2px;
}

.checkoutStep li:last-child::after {
display: none;
}

/*=========================================================*/
.flotting-group {
width: 100%;
position: relative;
}

.flotting-group label {
position: absolute;
left: 8px;
font-size: 13.2px;
top: 8px;
visibility: hidden;
opacity: 0;
-webkit-transition: top 0.5s, left 0.5s, font 0.5s;
transition: top 0.5s, left 0.5s, font 0.5s;
}

.flotting-group .label {
position: absolute;
pointer-events: none;
top: -8px;
left: 10px;
background-color: #FFF;
padding: 0px 5px;
font-size: 10px;
visibility: visible;
opacity: 1;
font-weight: 500;
text-transform: uppercase;
color: var(--color-primary);
}

.flotting-group input {
background: #fff;
border: 1px solid var(--color-gray-200);
box-sizing: border-box;
border-radius: 0px;
padding: 0 15px;
display: block;
width: 100%;
height: 50px;
font-size: 14px;
line-height: 22px;
box-shadow: none;
}

.flotting-group>.form-control,
.flotting-group>.form-select {
height: calc(3rem + 2px);
line-height: 1.25;
font-size: 14px;
border-radius: 0px;
}

/*=========================================================*/
.appbanner-first img {
width: calc(100% - 95px);
height: 25vw;
margin-bottom: 20px;
object-fit: cover;
}

.appbanner-second {
width: fit-content;
display: block;
margin-left: auto;
margin-top: 70px;
}

.appbanner-second img {
width: 23vw;
min-height: 448px;
height: 31vw;
object-fit: cover;
margin-bottom: 20px;
}

/*=========================================================*/
.overlay-zoom {
overflow: hidden
}

.overlay-zoom figure {
overflow: hidden
}

.overlay-zoom img {
transition: transform 0.3s
}

.overlay-zoom:hover img {
transform: scale(1.08)
}

/*=========================================================*/
.jewlerysiderbar {
position: -webkit-sticky;
position: sticky;
top: 120px;
left: 0;
}

.careTablist {
list-style: none;
margin: 0;
padding: 0;
}

.careTablist li {
cursor: pointer;
font-size: 18px;
line-height: 42px;
width: 100%;
display: inline-block;
position: relative;
}

.careTablist li:hover {
color: var(--color-secondary);
}

.careTablist li.active {
color: var(--color-secondary);
}

.contactlist {
list-style: none;
margin: 0;
padding: 0;
}

.contactlist li {
cursor: pointer;
font-size: 18px;
line-height: 42px;
width: 100%;
display: inline-block;
position: relative;
}

/*=========================================================*/
.sizetabs {
width: 100%;
position: relative;
}
.sizetabs .nav-tabs {
justify-content: center;
gap: 30px;
border-bottom: 1px solid var(--color-gray-200);
padding-bottom: 40px;
}
.sizetabs .nav-link {
color: var(--color-dark);
border-radius: 0px;
border: 0;
padding: 12px 15px 8px !important;
font-size: 24px;
}
.sizetabs .nav-link.active {
border: 0;
background: var(--color-light);
border-radius: 0px;
padding: 12px 15px 8px !important;
}
.sizetabs .nav-link::after {
display: none;
}
.sizetabs .nav-link:hover {
background: var(--color-light);
border: 0;
}
.sizetabsbox{
padding: 90px;
}
@media only screen and (max-width:968px) {
.sizetabs .nav-tabs {
justify-content: center;
gap: 10px;
border-bottom: 1px solid var(--color-gray-200);
padding-bottom: 40px;
}
.sizetabs .nav-link {
color: var(--color-dark);
border-radius: 0px;
border: 0;
padding: 12px 15px 8px !important;
font-size: 16px;
}
.sizetabsbox{
padding: 15px;
}
}
/*=========================================================*/
.cols-1>* {
max-width: 100%;
flex: 0 0 100%
}

.cols-2>* {
max-width: 50%;
flex: 0 0 50%
}

.cols-3>* {
max-width: 33.3333%;
flex: 0 0 33.3333%
}

.cols-4>* {
max-width: 25%;
flex: 0 0 25%
}

.cols-5>* {
max-width: 20%;
flex: 0 0 20%
}

.cols-6>* {
max-width: 16.6667%;
flex: 0 0 16.6667%
}

.cols-7>* {
max-width: 14.2857%;
flex: 0 0 14.2857%
}

.cols-8>* {
max-width: 12.5%;
flex: 0 0 12.5%
}

@media (min-width:480px) {
.cols-xs-1>* {
max-width: 100%;
flex: 0 0 100%
}

.cols-xs-2>* {
max-width: 50%;
flex: 0 0 50%
}

.cols-xs-3>* {
max-width: 33.3333%;
flex: 0 0 33.3333%
}

.cols-xs-4>* {
max-width: 25%;
flex: 0 0 25%
}

.cols-xs-5>* {
max-width: 20%;
flex: 0 0 20%
}

.cols-xs-6>* {
max-width: 16.6667%;
flex: 0 0 16.6667%
}

.cols-xs-7>* {
max-width: 14.2857%;
flex: 0 0 14.2857%
}

.cols-xs-8>* {
max-width: 12.5%;
flex: 0 0 12.5%
}
}

@media (min-width:576px) {
.cols-sm-1>* {
max-width: 100%;
flex: 0 0 100%
}

.cols-sm-2>* {
max-width: 50%;
flex: 0 0 50%
}

.cols-sm-3>* {
max-width: 33.3333%;
flex: 0 0 33.3333%
}

.cols-sm-4>* {
max-width: 25%;
flex: 0 0 25%
}

.cols-sm-5>* {
max-width: 20%;
flex: 0 0 20%
}

.cols-sm-6>* {
max-width: 16.6667%;
flex: 0 0 16.6667%
}

.cols-sm-7>* {
max-width: 14.2857%;
flex: 0 0 14.2857%
}

.cols-sm-8>* {
max-width: 12.5%;
flex: 0 0 12.5%
}
}

@media (min-width:768px) {
.cols-md-1>* {
max-width: 100%;
flex: 0 0 100%
}

.cols-md-2>* {
max-width: 50%;
flex: 0 0 50%
}

.cols-md-3>* {
max-width: 33.3333%;
flex: 0 0 33.3333%
}

.cols-md-4>* {
max-width: 25%;
flex: 0 0 25%
}

.cols-md-5>* {
max-width: 20%;
flex: 0 0 20%
}

.cols-md-6>* {
max-width: 16.6667%;
flex: 0 0 16.6667%
}

.cols-md-7>* {
max-width: 14.2857%;
flex: 0 0 14.2857%
}

.cols-md-8>* {
max-width: 12.5%;
flex: 0 0 12.5%
}
}

@media (min-width:992px) {
.cols-lg-1>* {
max-width: 100%;
flex: 0 0 100%
}

.cols-lg-2>* {
max-width: 50%;
flex: 0 0 50%
}

.cols-lg-3>* {
max-width: 33.3333%;
flex: 0 0 33.3333%
}

.cols-lg-4>* {
max-width: 25%;
flex: 0 0 25%
}

.cols-lg-5>* {
max-width: 20%;
flex: 0 0 20%
}

.cols-lg-6>* {
max-width: 16.6667%;
flex: 0 0 16.6667%
}

.cols-lg-7>* {
max-width: 14.2857%;
flex: 0 0 14.2857%
}

.cols-lg-8>* {
max-width: 12.5%;
flex: 0 0 12.5%
}
}

@media (min-width:1200px) {
.cols-xl-1>* {
max-width: 100%;
flex: 0 0 100%
}

.cols-xl-2>* {
max-width: 50%;
flex: 0 0 50%
}

.cols-xl-3>* {
max-width: 33.3333%;
flex: 0 0 33.3333%
}

.cols-xl-4>* {
max-width: 25%;
flex: 0 0 25%
}

.cols-xl-5>* {
max-width: 20%;
flex: 0 0 20%
}

.cols-xl-6>* {
max-width: 16.6667%;
flex: 0 0 16.6667%
}

.cols-xl-7>* {
max-width: 14.2857%;
flex: 0 0 14.2857%
}

.cols-xl-8>* {
max-width: 12.5%;
flex: 0 0 12.5%
}
}

@media (min-width:1600px) {
.cols-xxl-1>* {
max-width: 100%;
flex: 0 0 100%
}

.cols-xxl-2>* {
max-width: 50%;
flex: 0 0 50%
}

.cols-xxl-3>* {
max-width: 33.3333%;
flex: 0 0 33.3333%
}

.cols-xxl-4>* {
max-width: 25%;
flex: 0 0 25%
}

.cols-xxl-5>* {
max-width: 20%;
flex: 0 0 20%
}

.cols-xxl-6>* {
max-width: 16.6667%;
flex: 0 0 16.6667%
}

.cols-xxl-7>* {
max-width: 14.2857%;
flex: 0 0 14.2857%
}

.cols-xxl-8>* {
max-width: 12.5%;
flex: 0 0 12.5%
}
}

.order-first {
order: -1
}

.order-last {
order: 9
}

@media (min-width:576px) {
.order-sm-auto {
order: 0
}

.order-sm-first {
order: -1
}

.order-sm-last {
order: 9
}
}

@media (min-width:768px) {
.order-md-auto {
order: 0
}

.order-md-first {
order: -1
}

.order-md-last {
order: 9
}
}

@media (min-width:992px) {
.order-lg-auto {
order: 0
}

.order-lg-first {
order: -1
}

.order-lg-last {
order: 9
}
}

@media (min-width:1200px) {
.order-xl-auto {
order: 0
}

.order-xl-first {
order: -1
}

.order-xl-last {
order: 9
}
}

/*=========================================================*/
.catfooterTabs .nav-tabs {
justify-content: center;
border-bottom: 0;
}

.catfooterTabs .nav-tabs .nav-link {
color: var(--color-dark);
border: 0px;
padding: 10px 10px 0px !important;
border-bottom: 3px solid transparent;
}

.catfooterTabs .tab-pane {
padding: 15px;
}

.catfooterTabs .nav-tabs .nav-link.active {
border-bottom: 3px solid var(--color-secondary);
}

/*=========================================================*/
/*=========================================================*/
/*=========================================================*/
/*=========================================================*/
/*=========================================================*/
/*=========================================================*/
/*=========================================================*/
/*=========================================================*/
/*=========================================================*/
/*=========================================================*/
@keyframes fadeInDelay {
0% {
opacity: 0
}

25% {
opacity: 0
}

50% {
opacity: 0
}

75% {
opacity: .3
}

100% {
opacity: 1
}
}

/*=========================================================*/
.el-radio,
.el-checkbox {
position: relative;
font-size: 100%;
display: flex;
align-items: center;
gap: 5px;
padding: 3px 0px;
font-size: 15px;
}

label.el-radio,
label.el-checkbox {
display: block;
cursor: pointer;
}

.el-radio>input[type="radio"],
.el-checkbox>input[type="checkbox"] {
display: none;
}

.el-radio>input[type="radio"][disabled],
.el-checkbox>input[type="checkbox"][disabled] {
cursor: not-allowed;
}

.el-radio>input[type="radio"]+.el-radio-style,
.el-checkbox>input[type="checkbox"]+.el-checkbox-style {
position: relative;
display: inline-block;
width: 1.4em;
height: 1.4em;
vertical-align: middle;
cursor: pointer;
}

.el-radio>input[type="radio"]+.el-radio-style:hover:before,
.el-checkbox>input[type="checkbox"]+.el-checkbox-style:hover:before {
border-color: #20a0ff;
}

.el-radio>input[type="radio"]+.el-radio-style:before,
.el-checkbox>input[type="checkbox"]+.el-checkbox-style:before {
position: absolute;
top: 0;
left: 0;
display: inline-block;
width: 1.2em;
height: 1.2em;
content: '';
border: 1px solid #C0CCDA;
}

.el-radio>input[type="radio"]+.el-radio-style:after,
.el-checkbox>input[type="checkbox"]+.el-checkbox-style:after {
position: absolute;
display: none;
content: '';
}

.el-radio>input[type="radio"][disabled]+.el-radio-style,
.el-checkbox>input[type="checkbox"][disabled]+.el-checkbox-style {
cursor: not-allowed;
color: #D3DCE6;
}

.el-radio>input[type="radio"][disabled]+.el-radio-style:hover,
.el-radio>input[type="radio"][disabled]+.el-radio-style:before,
.el-radio>input[type="radio"][disabled]+.el-radio-style:after,
.el-checkbox>input[type="checkbox"][disabled]+.el-checkbox-style:hover,
.el-checkbox>input[type="checkbox"][disabled]+.el-checkbox-style:before,
.el-checkbox>input[type="checkbox"][disabled]+.el-checkbox-style:after {
cursor: not-allowed;
}

.el-radio>input[type="radio"][disabled]+.el-radio-style:hover:before,
.el-checkbox>input[type="checkbox"][disabled]+.el-checkbox-style:hover:before {
border: 1px solid #D3DCE6;
animation-name: none;
}

.el-radio>input[type="radio"][disabled]+.el-radio-style:before,
.el-checkbox>input[type="checkbox"][disabled]+.el-checkbox-style:before {
border-color: #D3DCE6;
}

.el-radio>input[type="radio"]:checked+.el-radio-style:before,
.el-checkbox>input[type="checkbox"]:checked+.el-checkbox-style:before {
animation-name: none;
}

.el-radio>input[type="radio"]:checked+.el-radio-style:after,
.el-checkbox>input[type="checkbox"]:checked+.el-checkbox-style:after {
display: block;
}

.el-radio>input[type="radio"]+.el-radio-style:before {
border-radius: 50%;
}

.el-radio>input[type="radio"]+.el-radio-style:after {
top: 0.4em;
left: 0.4em;
width: 0.6em;
height: 0.6em;
border-radius: 50%;
background: #20a0ff;
}

.el-radio>input[type="radio"]:checked+.el-radio-style:before {
border: 1px solid #20a0ff;
}

.el-radio>input[type="radio"]:checked[disabled]+.el-radio-style:before {
border: 1px solid #b0d7f5;
}

.el-radio>input[type="radio"]:checked[disabled]+.el-radio-style:after {
background: #b0d7f5;
}

.el-checkbox>input[type="checkbox"]+.el-checkbox-style:before {
border-radius: 3px;
}

.el-checkbox>input[type="checkbox"]+.el-checkbox-style:after {
top: 0.20em;
left: 0.45em;
box-sizing: border-box;
width: 0.3em;
height: 0.65em;
transform: rotate(45deg);
border-right: 2px solid #fff;
border-bottom: 2px solid #fff;
}

.el-checkbox>input[type="checkbox"]:checked+.el-checkbox-style:before {
border: var(--color-primary);
background: var(--color-primary);
}

.el-checkbox>input[type="checkbox"]:checked[disabled]+.el-checkbox-style:before {
border: #b0d7f5;
background: #b0d7f5;
}

/*blue theme*/
.el-radio.el-radio-blue>input[type="radio"]+label:hover:before,
.el-checkbox.el-checkbox-blue>input[type="checkbox"]+label:hover:before,
.el-radio.el-radio-blue>input[type="radio"]:checked+label:before,
.el-checkbox.el-checkbox-blue>input[type="checkbox"]:checked+label:before {
border-color: #20a0ff;
}

.el-checkbox.el-checkbox-blue>input[type="checkbox"]:checked+label:before,
.el-radio.el-radio-blue>input[type="radio"]+label:after {
background: #20a0ff;
}

.el-radio.el-radio-blue>input[type="radio"][disabled]+label:hover:before,
.el-checkbox.el-checkbox-blue>input[type="checkbox"][disabled]+label:hover:before {
border-color: #D3DCE6;
}

.el-checkbox.el-checkbox-blue>input[type="checkbox"]:checked[disabled]+label:before,
.el-radio.el-radio-blue>input[type="radio"]:checked[disabled]+label:before {
border-color: #b0d7f5;
}

.el-checkbox.el-checkbox-blue>input[type="checkbox"][disabled]:checked+label:before,
.el-radio.el-radio-blue>input[type="radio"]:checked[disabled]+label:after {
background: #b0d7f5;
}

/*green theme*/
.el-radio.el-radio-green>input[type="radio"]+label:hover:before,
.el-checkbox.el-checkbox-green>input[type="checkbox"]+label:hover:before,
.el-radio.el-radio-green>input[type="radio"]:checked+label:before,
.el-checkbox.el-checkbox-green>input[type="checkbox"]:checked+label:before {
border-color: #13ce66;
}

.el-checkbox.el-checkbox-green>input[type="checkbox"]:checked+label:before,
.el-radio.el-radio-green>input[type="radio"]+label:after {
background: #13ce66;
}

.el-radio.el-radio-green>input[type="radio"][disabled]+label:hover:before,
.el-checkbox.el-checkbox-green>input[type="checkbox"][disabled]+label:hover:before {
border-color: #D3DCE6;
}

.el-checkbox.el-checkbox-green>input[type="checkbox"]:checked[disabled]+label:before,
.el-radio.el-radio-green>input[type="radio"]:checked[disabled]+label:before {
border-color: #a1efc4;
}

.el-checkbox.el-checkbox-green>input[type="checkbox"][disabled]:checked+label:before,
.el-radio.el-radio-green>input[type="radio"]:checked[disabled]+label:after {
background: #a1efc4;
}

/*red theme*/
.el-radio.el-radio-red>input[type="radio"]+label:hover:before,
.el-checkbox.el-checkbox-red>input[type="checkbox"]+label:hover:before,
.el-radio.el-radio-red>input[type="radio"]:checked+label:before,
.el-checkbox.el-checkbox-red>input[type="checkbox"]:checked+label:before {
border-color: #ff4949;
}

.el-checkbox.el-checkbox-red>input[type="checkbox"]:checked+label:before,
.el-radio.el-radio-red>input[type="radio"]+label:after {
background: #ff4949;
}

.el-radio.el-radio-red>input[type="radio"][disabled]+label:hover:before,
.el-checkbox.el-checkbox-red>input[type="checkbox"][disabled]+label:hover:before {
border-color: #D3DCE6;
}

.el-checkbox.el-checkbox-red>input[type="checkbox"]:checked[disabled]+label:before,
.el-radio.el-radio-red>input[type="radio"]:checked[disabled]+label:before {
border-color: #f9b3b3;
}

.el-checkbox.el-checkbox-red>input[type="checkbox"][disabled]:checked+label:before,
.el-radio.el-radio-red>input[type="radio"]:checked[disabled]+label:after {
background: #f9b3b3;
}

/*yellow theme*/
.el-radio.el-radio-yellow>input[type="radio"]+label:hover:before,
.el-checkbox.el-checkbox-yellow>input[type="checkbox"]+label:hover:before,
.el-radio.el-radio-yellow>input[type="radio"]:checked+label:before,
.el-checkbox.el-checkbox-yellow>input[type="checkbox"]:checked+label:before {
border-color: #f7ba2a;
}

.el-checkbox.el-checkbox-yellow>input[type="checkbox"]:checked+label:before,
.el-radio.el-radio-yellow>input[type="radio"]+label:after {
background: #f7ba2a;
}

.el-radio.el-radio-yellow>input[type="radio"][disabled]+label:hover:before,
.el-checkbox.el-checkbox-yellow>input[type="checkbox"][disabled]+label:hover:before {
border-color: #D3DCE6;
}

.el-checkbox.el-checkbox-yellow>input[type="checkbox"]:checked[disabled]+label:before,
.el-radio.el-radio-yellow>input[type="radio"]:checked[disabled]+label:before {
border-color: #fbeac1;
}

.el-checkbox.el-checkbox-yellow>input[type="checkbox"][disabled]:checked+label:before,
.el-radio.el-radio-yellow>input[type="radio"]:checked[disabled]+label:after {
background: #fbeac1;
}

/*switch component*/
.el-switch,
.el-switch-style,
.el-switch-style:before {
-webkit-box-sizing: border-box;
-moz-box-sizing: border-box;
box-sizing: border-box;
}

.el-switch {
display: inline-block;
font-size: 100%;
height: 1.6em;
position: relative;
}

.el-switch .el-switch-style {
height: 1.6em;
left: 0;
background: #C0CCDA;
-webkit-border-radius: 0.8em;
border-radius: 0.8em;
display: inline-block;
position: relative;
top: 0;
-webkit-transition: all 0.3s ease-in-out;
transition: all 0.3s ease-in-out;
width: 3em;
cursor: pointer;
}

.el-switch .el-switch-style:before {
display: block;
content: '';
height: 1.4em;
position: absolute;
width: 1.4em;
background-color: #fff;
-webkit-border-radius: 50%;
border-radius: 50%;
left: 0.1em;
top: 0.1em;
-webkit-transition: all 0.3s ease-in-out;
transition: all 0.3s ease-in-out;
}

.el-switch>input[type="checkbox"] {
display: none;
}

.el-switch>input[type="checkbox"][disabled]+.el-switch-style {
cursor: not-allowed;
background-color: #D3DCE6;
}

.el-switch>input[type="checkbox"]:checked+.el-switch-style {
background-color: #20a0ff;
}

.el-switch>input[type="checkbox"]:checked+.el-switch-style:before {
left: 50%;
}

.el-switch>input[type="checkbox"]:checked[disabled]+.el-switch-style {
background-color: #b0d7f5;
}

.el-switch.el-switch-blue>input[type="checkbox"]:checked+.el-switch-style {
background-color: #20a0ff;
}

.el-switch.el-switch-blue>input[type="checkbox"]:checked[disabled]+.el-switch-style {
background-color: #b0d7f5;
}

.el-switch.el-switch-green>input[type="checkbox"]:checked+.el-switch-style {
background-color: #13ce66;
}

.el-switch.el-switch-green>input[type="checkbox"]:checked[disabled]+.el-switch-style {
background-color: #a1efc4;
}

.el-switch.el-switch-red>input[type="checkbox"]:checked+.el-switch-style {
background-color: #ff4949;
}

.el-switch.el-switch-red>input[type="checkbox"]:checked[disabled]+.el-switch-style {
background-color: #f9b3b3;
}

.el-switch.el-switch-yellow>input[type="checkbox"]:checked+.el-switch-style {
background-color: #f7ba2a;
}

.el-switch.el-switch-yellow>input[type="checkbox"]:checked[disabled]+.el-switch-style {
background-color: #fbeac1;
}

/*define size*/
.el-radio.el-radio-sm,
.el-checkbox.el-checkbox-sm,
.el-switch.el-switch-sm {
font-size: 85%;
}

.el-radio.el-radio-lg,
.el-checkbox.el-checkbox-lg,
.el-switch.el-switch-lg {
font-size: 125%;
}

/*=========================================================*/
.aclist {
width: 100%;
position: relative;
}
.aclist ul {
margin: 0;
padding: 0;
list-style: none;
}
.aclist ul li {
display: inline-block;
width: 100%;
position: relative;
line-height: 35px;
}
.aclist ul li a {
display: flex;
align-items: center;
gap: 5px;
}
.aclist ul li a i {
margin-top: -3px;
}
.recentbox {
width: 100%;
position: relative;
padding: 20px 0px;
border-bottom: 1px solid var(--color-gray-200);
margin-bottom: 10px;
}
/*=========================================================*/
.cartProdutbox {
width: 100%;
position: relative;
display: flex;
border: 1px solid #e1e1e1;
padding: 10px;
gap: 15px;
}
.cartProdutbox .cartProdutboxMedia {
width: 75px;
display: block;
overflow: hidden;
position: relative;
}
.cartProdutbox .cartProdutboxMedia::before {
content: "";
display: block;
padding-top: 100%;
}
.cartProdutbox .cartProdutboxMedia-thumbnail {
height: 100%;
left: 0;
position: absolute;
top: 0;
transition: box-shadow .3s;
width: 100%;
background: #fdfafa;
}
.cartProdutbox .cartProdutboxMedia-thumbnail img {
height: 100%;
object-fit: cover;
width: 100%;
mix-blend-mode: darken;
transition: all 0.5s;
}
.cartProdutboxContent {
flex: 1;
}
.cartProdutboxContent .title {
font-size: 14px;
line-height: 24px;
}
.cartProdutbox-footer {
margin-top: 15px;
display: flex;
align-items: center;
justify-content: space-between;
}
.cartProdutbox-footer-right {
display: flex;
align-items: center;
gap: 15px;
}
/*=========================================================*/

/*=========================================================*/
/*=========================================================*/
/*=========================================================*/
/*=========================================================*/
/*=========================================================*/
/*=========================================================*/
/*=========================================================*/



/* Neha payment section  */
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
}

/* Firefox */
input[type=number] {
-moz-appearance: textfield;
}

.ckboxbo {
background: #fff;
background: var(--color-white);
border: 1px solid #e3e7ed;
border: 1px solid var(--color-gray-200);
border-radius: 10px;
}

.ckboxbo.active {
background: #f4f5f8;
background: var(--color-gray-100);
border: 1px solid #ce8992;

}

.ckboxbo .ckboxbo-inner {
align-items: center;
display: flex;
height: 60px;
justify-content: space-between;
padding: 0 15px;
}

.ckboxbo-icon {
font-size: 16px;
font-weight: 600;
}

.ckboxbo .ckboxbo-itemscount,
.ckboxbo-icon {
align-items: center;
display: flex;
}

.ckboxbo.active .ckboxbo-itemscount .paymentselect {
background: #ce8992;
background: var(--color-primary);
border: 2px solid #3b4863;
border: 2px solid var(--color-gray-800);
}

.ckboxbo-itemscount .paymentselect {
border: 1px solid #97a3b9;
border: 1px solid var(--color-gray-500);
border-radius: 50%;
height: 20px;
width: 20px;
}


/*==================================================================*/
.thankusection {
width: 100%;
position: absolute;
display: flex;
align-items: center;
height: 100%;
left: 0;
top: 0;
}

.thankusection .thankusection-left {
width: 65%;
position: relative;
height: 100%;
padding: 50px 10px 50px 100px;
}

.thankusection .thankusection-right {
width: 35%;
position: relative;
height: 100%;
background: var(--color-black-light);
padding: 50px 30px;
}

.tsleft-header {
width: 100%;
position: relative;
display: flex;
align-items: center;
}

.tsleft-header-icon {
width: 65px;
height: 65px;
}

.tsleft-header-icon img {
width: 65px;
height: 65px;
border-radius: 50%;
}

.tsleft-header-text {
flex: 1;
padding-left: 15px;
}

.tsleft-box {
width: 100%;
position: relative;
border-radius: 5px;
background: var(--color-light02);
}

.tsleft-box .tsleft-box-header {
padding: 15px;
border-bottom: 1px solid var(--color-black-light-02);
}

.tsleft-box .tsleft-box-body {
padding: 15px;
}

.thankusection-footer {
display: flex;
align-items: center;
justify-content: space-between;
width: 100%;
}

@media (max-width: 991px) {
.thankusection {
flex-direction: column;
padding: 15px;
}

.thankusection .thankusection-left {
width: 100%;
position: relative;
height: auto;
padding: 0;
}

.thankusection .thankusection-right {
width: 100%;
position: relative;
height: auto;
padding: 0;
background: var(--color-black);
margin-top: 0px;
}

.thankusection .cartsec {
padding: 10px 0px;
border-bottom: 1px solid var(--color-black-light-02);
}

.thankusection-footer {
flex-direction: column;
}

.tsleft-header {
width: 100%;
position: relative;
display: flex;
align-items: center;
flex-direction: column;
justify-content: center;
text-align: center;
}

.tsleft-header-text {
flex: 1;
padding-left: 0px;
margin-top: 20px;
}
}

/*==================================================================*/
.ordersummaryToggle {
width: 100%;
position: relative;
}

.ordersummaryToggle .ordersummaryToggleHed {
padding: 15px;
border-bottom: 4px solid var(--color-gray);
width: 100%;
display: flex;
align-items: center;
justify-content: space-between;
position: relative;
background: var(--color-white);
}

.ordersummaryToggle .ordersummaryTogglecont {
background: var(--color-light02);
}

.ordersummaryTogglecont .cartPanel {
padding: 0px !important;
}

/*==================================================================*/
/*===============Anand CSS===============================*/
.cancleform {
width: 100%;
position: relative;
}

.cancleform ul {
margin: 0;
padding: 0;
list-style: none;
}

.cancleform ul li {
display: flex;
align-items: center;
height: 40px;
color: var(--color-black);
}

.cancleform ul li span {
margin-left: 10px;
}





/*==============================================================*/
.videoGalleryModal .modal-dialog {
max-width: 100%;
margin: 0;
display: flex;
align-items: center;
height: 100%;
display: flex;
align-items: center;
}
.videoGalleryModal .modal-content {
background: transparent;
border: 0px;
width: 100%;
height: 100%;
display: flex;
align-items: center;
}
.videoGalleryModal .modal-close {
position: absolute;
top: 15px;
right: 15px;
background: var(--color-gray-500);
border: 1px solid var(--color-gray-500);
border-radius: 50%;
color: var(--color-white);
width:42px;
height:42px;
display: flex;
align-items: center;
justify-content: center;
z-index: 9999;
font-size: 18px;
}
.videoGalleryModal .swiper{
height: 100%;
}
.videoGalleryModal .swiper-wrapper{
align-items:center;
}
.videoGalleryModal .swiper-slide {
display:flex;
align-items: center;
justify-content:center;
overflow:visible;
transition:all 200ms linear;
}
.videoGalleryModal .swiper-slide.swiper-slide-active .videoPopProduct {
width: 500px;
height:600px;
flex-shrink: 0;
display: flex;
align-items: center;
}
.videoGalleryModal .swiper-slide.swiper-slide-active .videoPopProduct .videoPopProduct-inner{
width: 400px;
margin: 0 auto;
height: 100%;
}
.videoGalleryModal .swiper-slide .videoPopProduct{
height:450px;
width:250px;
display:flex;
position: relative;
}
.videoGalleryModal .swiper-slide .videoPopProduct .videoPopProduct-inner{
width: 100%;
position: relative;
}
.videoGalleryModal video{
object-fit: cover;
}
.videoGalleryModalSwiper {
width: 100%;
position: relative;
height: 100%;
}
.videoGalleryModalSwiper .swiper-arrow {
display: flex;
align-items: center;
justify-content: space-between;
position: absolute;
width: 100%;
top: 50%;
z-index: 9;
left: 0;
}
.videoGalleryModalSwiper .swiper-arrow .prev-arrow,
.swiper-arrow .next-arrow {
width: 42px;
height: 42px;
background: var(--color-gray-100);
display: flex;
align-items: center;
justify-content: center;
border-radius: 50%;
}
.videoGalleryModalSwiper .swiper-arrow i {
font-size: 26px;
margin-top: 3px;
}
.videoGalleryModalSwiper .swiper-arrow .prev-arrow {
left: 20px;
position: absolute;
}
.videoGalleryModalSwiper .swiper-arrow .next-arrow {
right: 20px;
position: absolute;
}
.videoGalleryModalSwiper .swiper-arrow img {
height: auto !important;
}
@media only screen and (max-width:968px) {
.videoSwiper .swiper-arrow .prev-arrow {
left: -10px;
position: absolute;
}
.videoSwiper .swiper-arrow .next-arrow {
right: -10px;
position: absolute;
}
}
.volumicon{
cursor: pointer;
position: absolute;
display: none;
background: var(--color-dark);
width: 50px;
height: 50px;
border-radius: 5px;
align-items: center;
justify-content: center;
color: var(--color-white);
right: 15px;
top:15px;
z-index: 999;
}
.volumicon i{
font-size: 20px;
}
.videoGalleryModal .swiper-slide.swiper-slide-active .videoPopProduct .volumicon{
display: flex;
}
/*==============================================================*/
.newsletteModal.modal.left .modal-dialog,
.newsletteModal.modal.right .modal-dialog {
position: fixed;
margin: auto;
width: 780px;
max-width: 100%;
height: 100%;
-webkit-transform: translate3d(0%, 0, 0);
-ms-transform: translate3d(0%, 0, 0);
-o-transform: translate3d(0%, 0, 0);
transform: translate3d(0%, 0, 0);
}

.newsletteModal.modal.fade .modal-dialog {
transition: transform .3s ease-out;
transform: translate(0, 0px) !important;
}

.newsletteModal.modal.right.fade .modal-dialog {
right: -780px;
-webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
-moz-transition: opacity 0.3s linear, right 0.3s ease-out;
-o-transition: opacity 0.3s linear, right 0.3s ease-out;
transition: opacity 0.3s linear, right 0.3s ease-out;
}

.newsletteModal.modal.right.fade.show .modal-dialog {
right: 0;
}
.newsletteModal .modal-content{
background: transparent;
}
.newsletteModalContent{
width: 100%;
height: 550px;
position: fixed;
bottom: 0;
right: 0;
background: var(--color-white);
}
.newsletteModal-Close{
background: transparent;
top:15px;
right: 15px;
border: 0px;
font-size: 20px;
position: absolute;
}
@media only screen and (max-width:968px) {
.newsletteModalContent{
width: 100%;
height: 100%;
}
}
/*==============================================================*/
.categorySlider .swiper-wrapper{
justify-content: center;
}
/*==============================================================*/
.order-box {
position: relative;
background: var(--color-gray-100);
width: 100%;
display: flex;
border-radius: 5px;
margin-bottom: 20px;
}
.order-box .info {
position: relative;
width: 75%;
border-right: 1px dashed #e3e3e3;
padding: 15px;
}
.order-box .bcode {
position: relative;
width: 25%;
padding: 25px;
text-align: center;
}
.order-box .orderid-box {
background: var(--color-gray-01);
border-radius: 5px;
padding: 15px 10px 10px 10px;
}
.bs-wizard {
padding: 0;
}
.bs-wizard>.bs-wizard-step {
padding: 0;
position: relative;
}
.bs-wizard>.bs-wizard-step+.bs-wizard-step {}
.bs-wizard>.bs-wizard-step .bs-wizard-stepnum {
color: #595959;
font-size: 16px;
margin-bottom: 5px;
}
.bs-wizard>.bs-wizard-step .bs-wizard-info {
font-size: 14px;
margin-top: 15px;
}
.bs-wizard>.bs-wizard-step>.bs-wizard-dot {
position: absolute;
width: 20px;
height: 20px;
display: block;
background: #fbe8aa;
top: 17px;
left: 50%;
margin-top: -15px;
margin-left: -15px;
border-radius: 50%;
}
.bs-wizard>.bs-wizard-step>.bs-wizard-dot:after {
content: ' ';
width: 12px;
height: 12px;
background: #fbbd19;
border-radius: 50px;
position: absolute;
top: 4px;
left: 4px;
}
.bs-wizard>.bs-wizard-step>.progress {
position: relative;
border-radius: 0px;
height: 4px;
box-shadow: none;
margin: 10px 0;
}
.bs-wizard>.bs-wizard-step>.progress>.progress-bar {
width: 0px;
box-shadow: none;
background: #fbe8aa;
}
.bs-wizard>.bs-wizard-step.complete>.progress>.progress-bar {
width: 100%;
}
.bs-wizard>.bs-wizard-step.active>.progress>.progress-bar {
width: 50%;
}
.bs-wizard>.bs-wizard-step:first-child.active>.progress>.progress-bar {
width: 0%;
}
.bs-wizard>.bs-wizard-step:last-child.active>.progress>.progress-bar {
width: 100%;
}
.bs-wizard>.bs-wizard-step.disabled>.bs-wizard-dot {
background-color: var(--color-gray-200);
}
.bs-wizard>.bs-wizard-step.disabled>.bs-wizard-dot:after {
opacity: 0;
}
.bs-wizard>.bs-wizard-step:first-child>.progress {
left: 50%;
width: 50%;
}
.bs-wizard>.bs-wizard-step:last-child>.progress {
width: 50%;
}
.bs-wizard>.bs-wizard-step.disabled a.bs-wizard-dot {
pointer-events: none;
}
@media screen and (max-width:968px) {
.order-box .info {
position: relative;
width: 100%;
border-right: 0px dashed #e3e3e3;
padding: 15px;
}
}
/*==============================================================*/
.drophint .modal-dialog{
max-width: 700px;
}
.drophint .drophint-content{
padding: 25px;
}
.drophint-Close {
background: transparent;
top: 15px;
right: 15px;
border: 0px;
font-size: 20px;
position: absolute;
}
.drophint-content .drophint-content-left{
background: var(--color-primary);
text-align: center;
width: 100%;
position: relative;
padding: 25px;
}
/*==============================================================*/
.addressModal-content{
width: 100%;
position: relative;
padding: 25px;
}
.addressModal-Close {
background: transparent;
top: 15px;
right: 15px;
border: 0px;
font-size: 20px;
position: absolute;
z-index: 999;
}
/*==============================================================*/
.addressbox{
width: 100%;
border: 1px solid var(--color-gray-200);
padding: 15px;
}
/*==============================================================*/
.giftpop .modal-dialog{
max-width: 400px;
}
.giftpop .giftpop-content{
padding: 35px 25px;
}
.giftpop-Close {
background: transparent;
top: 15px;
right: 10px;
border: 0px;
font-size: 20px;
position: absolute;
}
.gift-amount-list{
margin: 0;
padding: 0;
list-style: none;
display: flex;
align-items: center;
gap: 10px;
}
.gift-amount-list li{
padding: 15px 20px 10px;
border: 1px solid var(--color-gray-200);
cursor: pointer;
margin-top: 10px;
}
.gift-amount-list li.active{
background: var(--color-secondary);
color: var(--color-white);
border: 1px solid var(--color-secondary);
}
/*==============================================================*/
.floating-whatsapp img {
    width: 55px;
}

.floating-whatsapp {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 99999;
}
/*==============================================================*/
.cartPrice ul {
    padding: 0px;
}
.cartProductInner {
    display: flex;
    gap: 20px;
}
.cartProduct-media img {
    min-width: 80px !important;
    max-width: 80px !important;
}
.cartPrice li {
    display: flex;
    justify-content: space-between;
    margin-top: 6px;
}
.tx-green {
    color: green;
}
span.price {
    font-weight: bold;
}
/*==============================================================*/
/*==============================================================*/
/*==============================================================*/
/*==============================================================*/
/*==============================================================*/
/*==============================================================*/
/*==============================================================*/